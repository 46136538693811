import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface MenuItemChild {
	Icon: IconProp;
	title: string;
	href: string;
	hash?: string;
	description?: string;
	colors: {
		primary: string;
		secondary: string;
		tertiary: string;
	};
}

export enum MenuItemType {
	Link = "link",
	Menu = "menu",
}

export interface MenuItem {
	id: number;
	title: string;
	href?: string;
	hash?: string;
	type: MenuItemType;
	menuItems?: MenuItemChild[];
}

export interface Menu {
	items: MenuItem[];
}

export interface NavbarDesktopMenuProps {
	title: string;
	menuItem: MenuItem;
	setSelectedMenuItem: (menuItem: MenuItem | null) => void;
	selectedMenuItem: MenuItem | null;
}

export interface NavbarDesktopMenuContentProps {
	selectedMenuItem: MenuItem | null;
	dir: null | "l" | "r";
}
