import { Dialog } from "@/components/ui/dialog/dialog";import { FC } from "react";
import * as t from "@/i18n/paraglide/messages";
import { faEnvelopeCircleCheck, faTriangleExclamation, faArrowRightToBracket } from "@fortawesome/pro-duotone-svg-icons";
import Button from "@/components/ui/button/button";
import { ButtonIconSide, ButtonSize } from "@/components/ui/button/helpers/buttonTypes";

export interface VerificationSuccessDialogProps {
	isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const VerificationSuccessDialog: FC<VerificationSuccessDialogProps> = ({ isOpen, setIsOpen }) => {

	return (
   <Dialog isOpen={isOpen} setIsOpen={setIsOpen} icon={faEnvelopeCircleCheck } title={t.verificationSuccessTitle()} duotoneIconColors={{ 
		primary: "success", secondary: "success" 
	}} 
		actionButtons={[
		<Button
			size={ButtonSize.Small}
			iconSide={ButtonIconSide.Right}
			icon={faArrowRightToBracket}
			label={t.loginAction()}
			onClick={() => setIsOpen(false)}
		/>
	]}>
			<span>{t.verificationSuccessText()}</span>
	 </Dialog>
	);
};

VerificationSuccessDialog.displayName = "VerificationSuccessDialog";
export default VerificationSuccessDialog;
