
import { StatusCardStatus, StatusCardVariant } from "@/components/ui/statusCard/helpers/statusCardTypes";
import StatusCard from "@/components/ui/statusCard/statusCard";
import { getSidebarItems } from "../helpers/sidebarData";
import SidebarItem from "./components/sidebarItemDesktop/sidebarItemDesktop";

/**
 * Represents the desktop version of the application's sidebar.
 *
 * This component is designed to be used in the desktop layout. It encapsulates
 * the logic and presentation for the sidebar specific to desktop views.
 *
 * @component
 * @returns {React.ReactElement} The desktop version of the app sidebar.
 */
const SidebarDesktop: React.FC = () => {
	const sidebarItems = getSidebarItems();

	return (
		<div className="border-green hidden h-screen w-20 grow flex-col overflow-y-auto border-r pb-6 sm:flex md:w-52">
			<div className="flex h-20 shrink-0 items-center justify-center">Logo</div>
			<nav className="flex flex-1 flex-col pt-4 sm:px-4">
				<div className="flex flex-1 flex-col gap-y-4 text-center text-sm">
					{sidebarItems.map((sidebarItem) => (
						<SidebarItem
							key={sidebarItem.page}
							page={sidebarItem.page}
							label={sidebarItem.label}
							link={sidebarItem.link}
							Icon={sidebarItem.icon}
						/>
					))}
				</div>
				<StatusCard
					status={StatusCardStatus.operational}
					className="flex h-11 sm:hidden md:flex"
				/>
				<StatusCard
					status={StatusCardStatus.operational}
					variant={StatusCardVariant.tile}
					className="hidden h-11 sm:flex md:hidden"
				/>
			</nav>
		</div>
	);
};

export default SidebarDesktop;
