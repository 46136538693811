import {
	faAnalytics,
	faCalendar,
	faImage,
	faRectangleList,
	faTablet,
	faUser,
} from "@fortawesome/pro-light-svg-icons";
import * as t from "@/i18n/paraglide/messages";
import type { SidebarItem } from "./sidebarTypes";
import { useRouter } from "@tanstack/react-router";
import { Page } from "@/store/sidebarState";


export const getSidebarItems = (): Array<SidebarItem> => {
	const {routesByPath} = useRouter();
	return [
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Dashboard,
			label: t.dashboard(),
			icon: faAnalytics,
		},
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Client,
			label: t.app(),
			icon: faTablet,
		},
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Leads,
			label: t.leads(),
			icon: faUser,
		},
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Forms,
			label: t.forms(),
			icon: faRectangleList,
		},
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Events,
			label: t.events(),
			icon: faCalendar,
		},
		{
			link: routesByPath["/app/dashboard"].fullPath,
			page: Page.Content,
			label: t.content(),
			icon: faImage,
		},
	];
};
