import {
	faDiagramPredecessor,
	faLanguage,
	faUpDownLeftRight,
} from "@fortawesome/pro-duotone-svg-icons";
import * as t from "@/i18n/paraglide/messages";
import type { FormFeature } from "./formFeatureTabsTypes";

export const FormFeatures: FormFeature[] = [
	{
		title: t.formFeatureDragAndDrop(),
		description: t.formFeatureDragAndDropDescription(),
		icon: faUpDownLeftRight,
		image: "/images/placeholder.webp",
	},
	{
		title: t.formFeatureActions(),
		description: t.formFeatureActionsDescription(),
		icon: faDiagramPredecessor,
		image: "/images/placeholder.webp",
	},
	{
		title: t.formFeatureInternationalisation(),
		description: t.formFeatureInternationalisationDescription(),
		icon: faLanguage,
		image: "/images/placeholder.webp",
	},
];
