import type { FieldValues } from "react-hook-form";
import type { FormTextInputProps } from "./helpers/formTextInputTypes";
import { FormControl, FormDescription, FormField, FormMessage } from "../../form";
import TextInput from "@/components/ui/textInput/textInput";
import FormItem from "../formItem/formItem";
import FormLabel from "../formLabel/formLabel";

function FormTextInput<T extends FieldValues>({
	label,
	name,
	description,
	placeholder,
	isRequired,
	form,
	isDisabled,
	hasHideOption,
}: FormTextInputProps<T>) {
	const { control } = form;
	return (
		<FormField
			control={control}
			name={name}
			render={({ field, fieldState }) => (
				<FormItem>
					<FormLabel>
						{label}
						{isRequired && label &&(
							<span
								className={fieldState.error ? "text-error" : "text-warning"}
							>
								*
							</span>
						)}
					</FormLabel>
					<FormControl>
						<TextInput
							placeholder={placeholder}
							{...field}
							disabled={isDisabled}
							hasHideOption={hasHideOption}
						/>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

FormTextInput.displayName = "FormTextInput";
export default FormTextInput;
