
export const mobileNavbarAnimation = {
	open: { opacity: 1, x: 0 },
	closed: { opacity: 0, x: "-100%" },
};

export const mobileNavbarTransition = {
	type: "keyframes",
	duration: 0.3,
};

export const mobileNavbarMenuItemAnimation = {
	open: (i: number) => ({
		opacity: 1,
		y: 0,
		transition: { delay: i * 0.15 },
	}),
	closed: { opacity: 0, y: 20 },
};

export const mobileNavbarMenuAnimation = {
	open: {
		opacity: 1,
		height: "auto",
	},
	closed: {
		opacity: 0,
		height: 0,
	},
};

