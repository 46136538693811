import SectionWrapper from "@/components/ui/layout/sectionWrapper/sectionWrapper";


const ContentFeature: React.FC = () => {
	return (
		<SectionWrapper title={"Content"} subtitle={"Subtitle"}>
				<div className="mx-auto max-w-7xl">
					<div className="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
						<div className="relative lg:row-span-2">
							<div className="absolute inset-px rounded-lg bg-background-tertiary border lg:rounded-l-[2rem]"></div>
							<div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
								<div className="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
									<p className="mt-2 text-lg/7 font-medium tracking-tight max-lg:text-center">
										Mobile friendly
									</p>
									<p className="mt-2 max-w-lg text-sm/6 text-foreground-secondary max-lg:text-center">
										Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.
									</p>
								</div>
								<div className="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
									<div className="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
										<img
											className="size-full object-cover object-top"
											src="https://tailwindui.com/plus/img/component-images/bento-03-mobile-friendly.png"
											alt=""
										/>
									</div>
								</div>
							</div>
							<div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div>
						</div>
						<div className="relative max-lg:row-start-1">
							<div className="absolute inset-px rounded-lg bg-background-tertiary border  max-lg:rounded-t-[2rem]"></div>
							<div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
								<div className="px-8 pt-8 sm:px-10 sm:pt-10">
									<p className="mt-2 tracking-tight max-lg:text-center text-2xl font-semibold">
										sfdhg
									</p>
									<p className="mt-2 max-w-lg text-sm/6 text-foreground-secondary max-lg:text-center">
										Lorem ipsum, dolor sit amet consectetur adipisicing elit maiores impedit.
									</p>
								</div>
							</div>
						</div>
						<div className="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2 rounded-lg bg-background-tertiary border">
								<div className="p-6 sm:p-8 justify-between flex flex-col h-full gap-3">
									<div className="max-lg:text-center ">
									<p className="text-2xl font-semibold">
										Performant
									</p>
									<p className="mt-2 max-w-lg text-sm/6 text-foreground-secondary">
									Lorem ipsum, dolor sit amet consectetur adipisicing elit maiores impedit.Lorem ipsum, dolor sit amet consectetur adipisicing elit maiores impedit.
									</p>
									</div>
								<div className="flex flex-1 [container-type:inline-size]">
								<div className="flex items-end justify-center w-full">
									<div className="flex gap-[0.4rem] md:gap-2 space-between items-end">
									<div className="w-2 h-[2rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-55"></div>
									<div className="w-2 h-[2.05rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-55"></div>
									<div className="w-2 h-[2.1rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-60"></div>
									<div className="w-2 h-[2.15rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-60"></div>
									<div className="w-2 h-[2.2rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-65"></div>
									<div className="w-2 h-[2.25rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-65"></div>
									<div className="w-2 h-[2.3rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-70"></div>
									<div className="w-2 h-[2.4rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-70"></div>
									<div className="w-2 h-[2.5rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-75"></div>
									<div className="w-2 h-[2.6rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-75"></div>
									<div className="w-2 h-[2.7rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-80"></div>
									<div className="w-2 h-[3rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-80"></div>
									<div className="w-2 h-[3.2rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-85"></div>
									<div className="w-2 h-[3.4rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-85"></div>
									<div className="w-2 h-[3.6rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-90"></div>
									<div className="w-2 h-[4rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-90"></div>
									<div className="w-2 h-[4.4rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-95"></div>
									<div className="w-2 h-[4.6rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm opacity-95"></div>
									<div className="w-2 h-[5rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm"></div>
									<div className="w-2 h-[5.5rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm"></div>
									<div className="w-2 h-[6rem] bg-gradient-to-t from-primary to-primary-400 rounded-sm"></div>
								</div>
								</div>
								</div>
							</div>
						</div>
						<div className="relative lg:row-span-2">
							<div className="absolute inset-px rounded-lg bg-background-tertiary border max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
							<div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
							<span>Bild vom Content Tree</span>
							</div>
							<div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"></div>
						</div>
					</div>
				</div>
		</SectionWrapper>
	);
};

ContentFeature.displayName = "ContentFeature";
export default ContentFeature;
