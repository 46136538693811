import { Link } from "@tanstack/react-router";
import { Icon } from "../icon/icon";
import { HoverCardIconSize, HoverCardProps, HoverCardVariant } from "./helpers/hoverCardProps";
import { getStylingIcon, getStylingParent } from "./helpers/hoverCardStyles";

/**
 * Represents a hoverable card component in the application.
 *
 * This component displays a card that can be hovered over to reveal more information. It typically includes a title,
 * description, and an icon. The card's appearance can be customized through various props including colors, icon size,
 * and different variants. The card might also include addition children elements and an optional information icon.
 *
 * @component
 * @param {string} props.title - The title of the hover card.
 * @param {string} props.description - The description text of the hover card.
 * @param {IconProp} props.Icon - The icon displayed on the hover card.
 * @param {HoverCardIconSize} [props.iconSize] - The size of the icon on the hover card.
 * @param {{ primary: string, secondary: string, tertiary: string }} props.colors - The color scheme for the hover card.
 * @param {boolean} [props.hasInfoIcon] - Indicates if an information icon should be displayed on the hover card.
 * @param {HoverCardVariant} props.variant - The variant of the hover card.
 * @param {() => void} [props.onClick] - The click event handler for the hover card.
 * @returns {React.ReactElement} A hoverable card element.
 */
const HoverCard: React.FC<HoverCardProps> = ({
	title,
	description,
	icon,
	iconSize = HoverCardIconSize.Small,
	colors: { primary, secondary, tertiary },
	variant = HoverCardVariant.Bordered,
	onClick,
	href,
	hrefHash
}) => {
	const content = (
		<div
			className={getStylingParent({ variant, colors: { secondary, tertiary } })}
			onClick={onClick}
		>
			<div className={getStylingIcon({ iconSize })}>
				<Icon
					fixedWidth
					icon={icon}
					size={iconSize}
					primaryColor={primary}
					secondaryColor={secondary}
				/>
			</div>
			<div className="flex flex-col">
				<div className="text-md font-semibold duration-300 transition group-hover:text-[#eceef3]">{title}</div>
				{description && <div className="text-sm text-foreground-secondary">{description}</div>}
			</div>
		</div>
	);

	return <>{href ? <Link hash={hrefHash} to={href}>{content}</Link> : <>{content}</>}</>;
};

HoverCard.displayName = "HoverCard";
export default HoverCard;
