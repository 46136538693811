/* eslint-disable */
import { languageTag } from "../../runtime.js"
import * as de from "../de/download.js"
import * as en from "../en/download.js"
import * as pl from "../pl/download.js"
import * as fr from "../fr/download.js"
import * as es from "../es/download.js"
import * as it from "../it/download.js"


/**
 * This message has been compiled by [inlang paraglide](https://inlang.com/m/gerre34r/library-inlang-paraglideJs).
 *
 * - Don't edit the message's code. Use [Sherlock (VS Code extension)](https://inlang.com/m/r7kp499g/app-inlang-ideExtension),
 *   the [web editor](https://inlang.com/m/tdozzpar/app-inlang-finkLocalizationEditor) instead, or edit the translation files manually.
 * 
 * - The params are NonNullable<unknown> because the inlang SDK does not provide information on the type of a param (yet).
 * 
 * @param {{}} params
 * @param {{ languageTag?: "de" | "en" | "pl" | "fr" | "es" | "it" }} options
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const download = (params = {}, options = {}) => {
	return {
		de: de.download,
		en: en.download,
		es: es.download,
		fr: fr.download,
		it: it.download,
		pl: pl.download
	}[options.languageTag ?? languageTag()]()
}

