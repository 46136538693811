import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_landingPageLayout/(auth)/usage/messelead-managment',
)({
  component: () => (
    <div>Hello messelead-managment!</div>
  ),
})



