import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";
import { cn } from "@/lib/utils";
import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "../icon/icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconSize } from "../icon/helpers/iconTypes";

export interface DialogProps extends DialogPrimitive.DialogProps {
	icon?: IconProp,
	duotoneIconColors?: {
		primary: string;
		secondary: string;
	};
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	title: string;
	actionButtons?: React.ReactNode[];
	children: React.ReactNode;
}

const Dialog: FC<DialogProps> = ({ icon, duotoneIconColors, isOpen, setIsOpen, title, actionButtons, children }) => (
	<DialogPrimitive.Root open={isOpen} onOpenChange={setIsOpen}>
		<DialogPrimitive.Portal>
			<div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center">
				<DialogPrimitive.Overlay className={cn("bg-background/80 fixed inset-0 z-50 backdrop-blur-sm transition-all duration-300")} />
				<DialogPrimitive.Content
					className={cn(
						"fixed z-50 grid w-full gap-8 rounded-b-lg border bg-background p-6 shadow-lg animate-in sm:max-w-lg sm:rounded-md",
					)}
				>
					<div className="flex flex-col gap-5 text-center sm:text-left">
						<DialogPrimitive.Title className={cn("text-xl font-semibold leading-none tracking-tight")}>
							<div className="absolut top-0 left-2">
							{icon && (
									<>
										{icon && duotoneIconColors ? (
											<Icon
												fixedWidth
												icon={icon}
												primaryColor={duotoneIconColors.primary}
												secondaryColor={duotoneIconColors.secondary}
											/>
										) : (
											<FontAwesomeIcon
												fixedWidth
												size={IconSize.Medium}
												icon={icon}
											/>
										)}
									</>
								)}
								{title}
									</div>
						</DialogPrimitive.Title>
						<div>{children}</div>
					</div>
					<div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
							{actionButtons?.map((button, index) => (
								<React.Fragment key={index}>{button}</React.Fragment>
							))}
					</div>
				</DialogPrimitive.Content>
			</div>
		</DialogPrimitive.Portal>
	</DialogPrimitive.Root>
);

export { Dialog };
