import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProperties, IconProps } from "./helpers/iconTypes";
import { CSSProperties } from "react";

/**
 * DuotoneIcon Component.
 *
 * Extends the FontAwesomeIcon component to support duotone icons with customizable
 * primary and secondary colors. The primary and secondary colors are set via CSS custom properties.
 *
 * @component
 *
 * @param {object} props - Properties passed to the component
 * @param {string} [props.primaryColor] - Primary color for the duotone icon
 * @param {string} [props.secondaryColor] - Secondary color for the duotone icon
 * @param {...object} props.style - Other styles that can be applied to the icon
 *
 * @returns {React.Element} Rendered DuotoneIcon component with custom colors
 *
 * @see {@link https://fontawesome.com/how-to-use/on-the-web/styling/duotone-icons} For more info on FontAwesome's duotone icons
 */
export function Icon({
	className = "",
	primaryColor,
	secondaryColor,
	...props
}: IconProps) {
	const style: CSSProperties & IconProperties = props.style
		? { ...props.style }
		: {};
	style["--fa-primary-color"] = primaryColor ? `var(--${primaryColor})` : "";
	style["--fa-secondary-color"] = secondaryColor
		? `var(--${secondaryColor})`
		: "";

	return <FontAwesomeIcon {...props} style={style} className={className} />;
}
