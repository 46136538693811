
export const mobileNavbarAnimation = {
	open: { opacity: 1, x: 0 },
	closed: { opacity: 0, x: "-100%" },
};

export const mobileNavbarTransition = {
	type: "keyframes",
	duration: 0.3,
};
