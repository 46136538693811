import { motion } from "framer-motion";

const PulseLogo = () => {
	return (
		<div className="relative">
			<motion.svg
				width="50"
				height="39"
				viewBox="0 0 50 39"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="relative z-10 fill-violet-100"
				initial={{
					opacity: 0,
					scale: 0.85,
				}}
				animate={{
					opacity: 1,
					scale: 1,
				}}
				transition={{
					duration: 1,
					ease: "easeOut",
				}}
			>
				<path
					d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
					stopColor="#fff"
				/>
				<path
					d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
					stopColor="#fff"
				/>
			</motion.svg>
			<Band delay={0} />
			<Band delay={8 * 0.25} />
			<Band delay={8 * 0.5} />
			<Band delay={8 * 0.75} />
		</div>
	);
};

const Band = ({ delay }: { delay: number }) => {
	return (
		<motion.span
			style={{
				translateX: "-50%",
				translateY: "-50%",
			}}
			initial={{
				opacity: 0,
				scale: 0.25,
			}}
			animate={{
				opacity: [0, 1, 1, 0],
				scale: 1,
			}}
			transition={{
				repeat: Number.POSITIVE_INFINITY,
				repeatType: "loop",
				times: [0, 0.5, 0.75, 1],
				duration: 8,
				ease: "linear",
				delay,
			}}
			className="absolute left-[50%] top-[50%] z-0 size-56 rounded-full border border-primary bg-gradient-to-br from-[#3B58E8]/30 to-[#3B58E8]/10 shadow-xl "
		/>
	);
};

PulseLogo.displayName = "PulseLogo";
export default PulseLogo;
