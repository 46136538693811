import { useEffect, useState } from "react";
import type { SpinningLogoSize } from "./spinningLogosTypes";

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState<SpinningLogoSize>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener("resize", handleResize);

		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
};

export const degreesToRadians = (degrees: number) => {
	return degrees * (Math.PI / 180);
};
