import { AnimatePresence, motion, useScroll, useSpring } from "framer-motion";
//import LanguageSelect from "@/components/Atoms/LanguageSelect/LanguageSelect";
import * as t from "@/i18n/paraglide/messages";
import { faArrowRightToBracket } from "@fortawesome/pro-duotone-svg-icons";
import { useState } from "react";
import { getNavbarData } from "../helpers/navbarData";
import type { MenuItem } from "../helpers/navbarTypes";
import NavbarDesktopMenu from "./components/navbarDesktopMenu";
import NavbarDesktopMenuContent from "./components/navbarDesktopMenuContent";
import { Link } from "@tanstack/react-router";
import Button from "@/components/ui/button/button";
import { ButtonIconSide } from "@/components/ui/button/helpers/buttonTypes";
import { appLanguages } from "@/lib/languages";
import LanguageSelect from "@/components/ui/languageSelect/languageSelect";

const NavbarDesktop: React.FC = () => {
	const { scrollYProgress } = useScroll();

	const windowScroll = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001,
	});

	const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(
		null,
	);
	const [dir, setDir] = useState<null | "l" | "r">(null);

	const menu = getNavbarData();

	const handleSetSelected = (val: MenuItem | null) => {
		if (
			typeof selectedMenuItem?.id === "number" &&
			typeof val?.id === "number"
		) {
			setDir(selectedMenuItem.id > val.id ? "r" : "l");
		} else if (val === null) {
			setDir(null);
		}
		setSelectedMenuItem(val);
	};

	return (
		<>
			{/*<NotificationBar /> */}
			<div className="mx-auto max-w-7xl hidden md:block">
				<div className="relative flex h-20 items-center justify-between px-6 lg:px-0">
					{/* Logo */}
					<div className="flex shrink-0 flex-col items-center">
						<Link to="/">Logo</Link>
					</div>
					{/* Menu */}
					<div
						className="flex items-center relative hover:cursor-pointer gap-2"
						onMouseLeave={() => handleSetSelected(null)}
					>
						{/* Tabs */}
						{menu.items.map((menuItem) => {
							return (
								<NavbarDesktopMenu
									key={menuItem.id}
									selectedMenuItem={selectedMenuItem}
									setSelectedMenuItem={handleSetSelected}
									menuItem={menuItem}
									title={menuItem.title}
								/>
							);
						})}
						{/* Content */}
						<AnimatePresence>
							{selectedMenuItem && selectedMenuItem.menuItems! && (
								<NavbarDesktopMenuContent
									dir={dir}
									selectedMenuItem={selectedMenuItem}
								/>
							)}
						</AnimatePresence>
					</div>
					{/* Actions */}
					<div className="flex gap-4">
						{/* Language */}
						<LanguageSelect
							languages={appLanguages}
							withSearch={false}
							compact={true}
						/>
						{/* Login */}
						<Link to="/login">
							<Button
								label={t.loginAction()}
								aria-label={t.loginAction()}
								icon={faArrowRightToBracket}
								iconSide={ButtonIconSide.Right}
							/>
						</Link>
					</div>
				</div>
				{/* Scrollbar */}
				<motion.div
					className="fixed inset-x-0 h-px origin-left bg-primary"
					style={{ scaleX: windowScroll }}
				/>
			</div>
		</>
	);
};

NavbarDesktop.displayName = "NavbarDesktop";
export default NavbarDesktop;
