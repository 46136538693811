import { cn } from "@/lib/utils";
import * as LabelPrimitive from "@radix-ui/react-label";
import { forwardRef, ElementRef, ComponentPropsWithoutRef } from "react";
import { useFormField } from "../../form";

const FormLabel = forwardRef<
	ElementRef<typeof LabelPrimitive.Root>,
	ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
	const { formItemId } = useFormField();

	return (
		<LabelPrimitive.Label
			ref={ref}
			className={cn("ml-2 text-sm", className)}
			htmlFor={formItemId}
			{...props}
		/>
	);
});

FormLabel.displayName = "FormLabel";
export default FormLabel;