import { CheckboxProps } from "./helpers/checkboxTypes";
import { Checkbox as CheckboxAria } from "react-aria-components";

/**
 * Checkbox component that wraps the `Checkbox` component from `react-aria-components`.
 * 
 * @component
 * @param {CheckboxProps} props - The properties passed to the Checkbox component.
 * @param {boolean} props.isSelected - Indicates whether the checkbox is selected.
 * @param {function} props.onChange - Callback function called when the checkbox state changes.
 * @param {string} props.ariaLabel - Accessible label for the checkbox.
 * @returns {JSX.Element} The rendered Checkbox component.
 */
export const Checkbox: React.FC<CheckboxProps> = ({ ...props }) => {
	return (
		<CheckboxAria
			isSelected={props.isSelected}
			onChange={props.onChange}
			aria-label={props.ariaLabel}
			{...props}
		>
			<div className="checkbox">
				<svg viewBox="0 0 18 18" aria-hidden="true">
					<polyline points="1 9 7 14 15 4" />
				</svg>
			</div>
		</CheckboxAria>
	);
};
