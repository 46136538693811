import { IServerRes } from "@/lib/responses";
import { RegisterFormValues } from "../data/registerFormSchema";
import apiClient from "@/lib/api";
import { AvailableLanguageTag } from "@/i18n/paraglide/runtime";

export const register = async ({
	registerFormValues,
}: { registerFormValues: RegisterFormValues & { language: AvailableLanguageTag } }): Promise<IServerRes> => {
	const response: IServerRes = await apiClient
		.post(`auth/register`, {
			json: registerFormValues,
		})
		.json<IServerRes>();
	return response;
};

export default register;
