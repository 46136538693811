import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

export interface IconProps extends FontAwesomeIconProps {
	primaryColor?: string;
	secondaryColor?: string;
	className?: string;
}

export type IconProperties = {
	"--fa-primary-color"?: string;
	"--fa-secondary-color"?: string;
};

export enum IconSize {
	VerySmall = "2xs",
	ExtraSmall = "xs",
	Small = "sm",
	Medium = "1x",
	Large = "lg",
	VeryLarge = "xl",
	ExtraLarge = "2xl",
}
