import * as t from "@/i18n/paraglide/messages";
import type * as React from "react";
import { Fragment, useState } from "react";
import { type AvailableLanguageTag, languageTag, onSetLanguageTag, setLanguageTag } from "@/i18n/paraglide/runtime";
import { faCheck, faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../button/button";
import { getFlag } from "@/lib/getFlag";
import { LanguageCode } from "@/lib/languages";
import { ButtonVariant } from "../button/helpers/buttonTypes";
import { LanguageSelectProps } from "./helper/languageSelectTypes";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/popover";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "../command/command";
import { useLanguage } from "@/providers/languageProvider";

const LanguageSelect: React.FC<LanguageSelectProps> = ({
	languages,
	compact,
	withSearch,
}) => {
	const [open, setOpen] = useState(false);
	const flagHeight = "1.5";
	const isPending = false;
	const { locale, setLocale } = useLanguage(); 

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant={ButtonVariant.Outline}
					aria-expanded={open}
					className={`${compact ? "w-max px-2" : "w-[150px]"} items-center justify-between`}
					isCustomButton={true}
				>
					{isPending ? (
						<div className={compact ? "size-[24px]" : "h-[24px] w-[150px]"} />
					) : (
						<>
							<div className="flex items-center gap-3">
								<>
									{getFlag(locale as LanguageCode, flagHeight)}
									{!compact && (
										<span>{languages[locale as LanguageCode].name}</span>
									)}
								</>
							</div>
							{!compact && (
								<FontAwesomeIcon
									icon={faChevronDown}
									className={`ml-2 size-4 shrink-0 opacity-50 transition-transform ${open ? "rotate-180" : ""}`}
								/>
							)}
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[160px] p-0">
				<Command>
					{withSearch ?? (
						<Fragment>
							<CommandInput placeholder={"TBD"} />
							<CommandEmpty>{t.noLanguageFound()}</CommandEmpty>
						</Fragment>
					)}
					<CommandGroup className="gap-0.5">
						{Object.entries(languages).map(([key, value]) => (
							<CommandItem
								key={key}
								value={key}
								onSelect={(e) => {
									setOpen(false);
									setLocale(key as AvailableLanguageTag);
									window.location.reload();
								}}
							>
								<div className="flex w-full items-center justify-between">
									<div className="flex">
										<span className="mr-3">
											{getFlag(value.locale, flagHeight)}
										</span>
										<div className="flex grow items-center justify-center">
											{value.name}
										</div>
									</div>
									{locale === key && (
										<span className="ml-2">
											<FontAwesomeIcon icon={faCheck} fixedWidth />
										</span>
									)}
								</div>
							</CommandItem>
						))}
					</CommandGroup>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

LanguageSelect.displayName = "LanguageSelect";
export default LanguageSelect;
