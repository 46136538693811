import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import { AnimatePresence, motion } from "framer-motion";
import { BillingPeriod, type PriceCardProps } from "../helpers/pricingTypes";
import {
	faCheckCircle,
	faXmarkCircle,
} from "@fortawesome/pro-duotone-svg-icons";

const PriceCard: React.FC<PriceCardProps> = ({
	tier,
	price,
	billingPeriod,
	pricePeriod,
	bestFor,
	Button,
	benefits,
}) => {
	return (
		<div className="relative size-full overflow-hidden rounded-2xl border p-6 w-full md:w-80 bg-background-tertiary">
			<div className="flex flex-col items-center border-b pb-6 relative">
				<span className="mb-4 inline-block text-lg">{tier}</span>
				<div className="flex items-end mb-3">
					<AnimatePresence mode="popLayout">
						<motion.span
							initial={{ y: 24, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							exit={{ y: -24, opacity: 0 }}
							key={price}
							transition={{ duration: 0.3, ease: "easeInOut" }}
							className="inline-block"
						>
							<span className="text-3xl font-semibold">{price}</span>
						</motion.span>
					</AnimatePresence>
					{pricePeriod && (
						<span className="text-lg text-foreground-secondary ml-1.5 items-end">
							{pricePeriod}
						</span>
					)}
					{tier !== "Enterprise" && (
						<AnimatePresence>
							{billingPeriod === BillingPeriod.Annually && (
								<motion.div
									className="absolute right-6 top-12 flex items-center justify-center h-6 w-12 rounded-md bg-accent ml-2"
									initial="hidden"
									animate="visible"
									exit="hidden"
									variants={{
										visible: { opacity: 1, scale: 1 },
										hidden: { opacity: 0, scale: 0 },
									}}
									transition={{ duration: 0.3, ease: "easeInOut" }}
								>
									<span className="text-sm tracking-wide">-20%</span>
								</motion.div>
							)}
						</AnimatePresence>
					)}
				</div>
				<span className="text-foreground-secondary">{bestFor}</span>
			</div>
			<div className="space-y-4 py-9">
				{benefits.map((benefit, index) => (
					<div className="flex items-center gap-3" key={index}>
						{benefit.checked ? (
							<Icon
								fixedWidth
								icon={faCheckCircle}
								primaryColor="success"
								secondaryColor="success-3"
								size={IconSize.Large}
							/>
						) : (
							<Icon
								fixedWidth
								icon={faXmarkCircle}
								size={IconSize.Large}
								primaryColor="blue-rinse"
								secondaryColor="blue-rinse-3"
							/>
						)}
						<span className="text-sm tracking-wide font-medium">{benefit.text}</span>
					</div>
				))}
			</div>
			{Button}
		</div>
	);
};

PriceCard.displayName = "PriceCard";
export default PriceCard;
