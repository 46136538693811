import { FormFeatures } from "../helpers/formFeatureTabsData";
import type { FormFeatureTabsHeaderProps } from "../helpers/formFeatureTabsTypes";
import FormFeatureTabsCard from "./formFeatureTabsCard";

const FormFeatureTabsHeader: React.FC<FormFeatureTabsHeaderProps> = ({
	selected,
	setSelected,
}) => {
	return (
		<div className="flex overflow-x-scroll md:overflow-x-hidden gap-3 pb-[5px]">
			{FormFeatures.map((tab, index) => {
				return (
					<FormFeatureTabsCard
						key={index}
						setSelected={setSelected}
						selected={selected === index}
						icon={tab.icon}
						title={tab.title}
						tabNum={index}
						isFirst={index === 0}
						isLast={index === FormFeatures.length - 1}
					/>
				);
			})}
		</div>
	);
};

FormFeatureTabsHeader.displayName = "FormFeatureTabsHeader";
export default FormFeatureTabsHeader;
