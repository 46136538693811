import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	label?: string;
	ariaLabel?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	variant?: ButtonVariant;
	size?: ButtonSize;
	color?: ButtonColor;
	icon?: IconProp;
	iconSide?: ButtonIconSide;
	isCustomButton?: boolean;
	children?: React.ReactNode;
	hasNotification?: boolean;
	hasAvatar?: boolean;
	type?: ButtonType;
	avatar?: string;
	isResponsive?: boolean;
	duotoneIconColors?: {
		primary: string;
		secondary: string;
	};
	onClick?: () => void;
}

export enum ButtonType {
	Button = "button",
	Submit = "submit",
	Reset = "reset",
}

export enum ButtonVariant {
	Fill = "fill",
	Outline = "outline",
	Ghost = "ghost",
	Input = "input",
}

export enum ButtonSize {
	ExtraSmall = "xs",
	Small = "sm",
	Medium = "md",
	Large = "lg",
}

export enum ButtonColor {
	Primary = "primary",
	Error = "error",
	Warning = "warning",
	Info = "info",
	Success = "success",
}

export enum ButtonIconSide {
	Left = "left",
	Right = "right",
}
