import NavbarDesktop from "./navbarDesktop/navbarDesktop";
import NavbarMobile from "./navbarMobile/navbarMobile";

const Navbar: React.FC = () => {
	return (
		<nav className="fixed z-50 w-full border-b bg-background">
			{/* Desktop */}
			<NavbarDesktop />
			{/* Mobile */}
			<NavbarMobile />
		</nav>
	);
};

export default Navbar;
