import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface HoverCardProps {
	title: string;
	description?: string;
	icon: IconProp;
	iconSize?: HoverCardIconSize;
	colors: {
		primary: string;
		secondary: string;
		tertiary: string;
	};
	children?: React.ReactNode;
	hasInfoIcon?: boolean;
	variant: HoverCardVariant;
	onClick?: () => void;
	href?: string;
	hrefHash?: string;
}

export enum HoverCardIconSize {
	Small = "lg",
	Large = "2xl",
}

export enum HoverCardVariant {
	Bordered = "bordered",
	Borderless = "borderless",
}
