import Button from "@/components/ui/button/button";
import { ButtonIconSide, ButtonType, ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import { Form } from "@/components/ui/form/form";
import { Separator } from "@/components/ui/separator/separator";
import * as t from "@/i18n/paraglide/messages";
import { faArrowRightToBracket, faUserPlus } from "@fortawesome/pro-duotone-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { LoginFormSchema } from "./data/loginFormSchema";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";


const LoginForm: React.FC = () => {
	const form = useForm<z.infer<typeof LoginFormSchema>>({
		resolver: zodResolver(LoginFormSchema),
		mode: "onBlur",
	});

	const sleep = (ms: number | undefined) =>
		new Promise((resolve) => setTimeout(resolve, ms));

	const onSubmit = async (data: unknown) => {
		await sleep(1000);
		alert(JSON.stringify(data, null, 2));
	};

	return (
<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col">
				<div className="mb-8 space-y-4">
					<FormTextInput
							form={form}
							name="email"
							label={t.email()}
							placeholder={t.email()}
							isRequired
							isDisabled={form.formState.isSubmitting}
					/>
					<FormTextInput
							form={form}
							name="password"
							label="Password"
							placeholder="Password"
							isRequired
							isDisabled={form.formState.isSubmitting}
					/>
					<p className="text-right text-[0.8rem] text-muted-foreground">
						<Link
							to={"/reset-password"}
							className="link text-foreground"
						>
							{t.forgotPassword()}
						</Link>
					</p>
				</div>
				<Button
					icon={faArrowRightToBracket}
					type={ButtonType.Submit}
					label={t.loginAction()}
					isDisabled={!form.formState.isValid}
					iconSide={ButtonIconSide.Right}
					className="w-full"
				/>
				<Separator className="w-12 self-center my-5 bg-foreground-muted" />
				<Link to="/registration">
					<Button
						variant={ButtonVariant.Outline}										
						label={t.registrationAction()}
						iconSide={ButtonIconSide.Right}
						icon={faUserPlus}
						className="w-full"
					/>
					</Link>
			</form>
		</Form>
	);
}

LoginForm.displayName = "LoginForm";
export default LoginForm;
