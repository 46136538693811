import FlagDE from "@/components/ui/flags/flagDe";
import FlagEN from "@/components/ui/flags/flagEn";
import FlagES from "@/components/ui/flags/flagEs";
import FlagFR from "@/components/ui/flags/flagFr";
import FlagIT from "@/components/ui/flags/flagIt";
import FlagPL from "@/components/ui/flags/flagPl";
import { LanguageCode } from "./languages";


export const getFlag = (languageCode: LanguageCode, flagHeight: string) => {
	switch (languageCode) {
		case LanguageCode.DE:
			return <FlagDE height={flagHeight} />;
		case LanguageCode.EN:
			return <FlagEN height={flagHeight} />;
		case LanguageCode.ES:
			return <FlagES height={flagHeight} />;
		case LanguageCode.FR:
			return <FlagFR height={flagHeight} />;
		case LanguageCode.IT:
			return <FlagIT height={flagHeight} />;
		case LanguageCode.PL:
			return <FlagPL height={flagHeight} />;
	}
};
