"use client";

import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Page, useSidebarState } from "@/store/sidebarState";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";
import { AnimatePresence, motion } from "framer-motion";

interface SidebarItemProps {
	page: Page;
	link: string;
	Icon: IconProp;
}

/**
 * Represents a mobile sidebar item in the application.
 *
 * This component displays a linkable item in the sidebar with an associated icon.
 * It takes a `page` from the Page enum which functions as title, a `link` URL, and an `Icon` component as props.
 *
 * @component
 * @param {Page} props.page - The page associated with the sidebar item.
 * @param {string} props.link - The URL to which the sidebar item links.
 * @param {IconProp} props.Icon - The icon displayed within the sidebar item.
 * @returns {React.ReactElement} A sidebar item element.
 */
const SidebarItemMobile: React.FC<SidebarItemProps> = ({
	page,
	link,
	Icon,
}) => {
	const activePage = useSidebarState((state) => state.activePage);

	return (
		<div>
			<Link href={link} className="rounded-md">
				<motion.button
					className="relative h-11 w-full rounded-md bg-background-tertiary text-md text-foreground duration-300 "
					onClick={() => useSidebarState.setState({ activePage: page })}
					whileTap={{ scale: 0.98 }}
				>
					<span className="relative z-10 cursor-pointer">
						<span className="flex py-2 pl-3">
							{activePage === page ? (
								<div className="flex items-center gap-2.5">
									<FontAwesomeIcon
										fixedWidth
										icon={Icon}
										size={IconSize.Medium}
										className="opacity-100"
									/>
									<span>{page}</span>
								</div>
							) : (
								<div className="flex w-full items-center justify-between pr-4">
									<div className="flex items-center gap-2.5">
										<FontAwesomeIcon
											fixedWidth
											icon={Icon}
											size={IconSize.Medium}
											className="opacity-60"
										/>
										<span>{page}</span>
									</div>
									<div>
										<FontAwesomeIcon
											fixedWidth
											icon={faChevronRight}
											size={IconSize.ExtraSmall}
										/>
									</div>
								</div>
							)}
						</span>
					</span>
					<AnimatePresence>
						{activePage === page && (
							<motion.span
								className="absolute inset-0 z-0 rounded-md bg-primary"
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								exit={{ scale: 0 }}
							/>
						)}
					</AnimatePresence>
				</motion.button>
			</Link>
		</div>
	);
};

export default SidebarItemMobile;
