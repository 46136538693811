import { Dialog } from "@/components/ui/dialog/dialog";import { FC } from "react";
import * as t from "@/i18n/paraglide/messages";
import { faTriangleExclamation, faUserPlus, faMessages } from "@fortawesome/pro-duotone-svg-icons";
import Button from "@/components/ui/button/button";
import { ButtonIconSide, ButtonSize, ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import { Link } from "@tanstack/react-router";


export interface VerificationErrorDialogProps {
	isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const VerificationErrorDialog: FC<VerificationErrorDialogProps> = ({ isOpen, setIsOpen }) => {

	return (
   <Dialog isOpen={isOpen} setIsOpen={setIsOpen} icon={faTriangleExclamation} title={t.verificationErrorTitle()} duotoneIconColors={{ 
		primary: "warning", secondary: "warning" 
	}} 
		actionButtons={[
      <Link href="/contact">
      <Button
			size={ButtonSize.Small}
			iconSide={ButtonIconSide.Right}
      variant={ButtonVariant.Outline}
			icon={faMessages}
			label={t.contact()}
			onClick={() => setIsOpen(false)}
		  />
    </Link>,
		<Button
			size={ButtonSize.Small}
			iconSide={ButtonIconSide.Right}
			icon={faUserPlus}
			label={t.registrationAction()}
			onClick={() => setIsOpen(false)}
		/>
	]}>
			<div className="flex flex-col gap-2">
        <span>Die Verifizierung Ihrer Email Adresse ist fehlgeschlagen. Sollte Ihre Registrierung mehr als eine Woche zurück liegen registrieren Sie sich bitte erneut. Fall dies nicht der Fall sein sollte kontaktieren Sie bitte unseren Support.</span>
      </div>
	 </Dialog>
	);
};

VerificationErrorDialog.displayName = "VerificationErrorDialog";
export default VerificationErrorDialog;
