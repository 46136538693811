import { Tabs as RadixTabs, TabsContent, TabsList } from "@radix-ui/react-tabs";
import type React from "react";
import { useState } from "react";
import Cursor from "./components/tabCursor";
import type { Position, TabsProps } from "./helpers/tabTypes";
import TabItem from "./components/tabItem";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

const Tabs: React.FC<TabsProps> = ({
	children,
	tabs,
	activeTab,
	handleClick,
	isDense = false,
	isLoading = false,
}) => {
	const [position, setPosition] = useState<Position>({
		left: 0,
		width: 0,
		opacity: 0,
	});

	return (
		<RadixTabs
			defaultValue={activeTab.id}
			onValueChange={(value) => {
				const tab = tabs.find((t) => t.id === value);
				if (tab) handleClick(tab);
			}}
			className="w-full"
		>
			<TabsList
				onMouseLeave={() => {
					setPosition((pv) => ({
						...pv,
						opacity: 0,
					}));
				}}
				className={clsx("relative flex w-fit rounded-md border items-center bg-background-secondary",
					isDense ? 'p-1 gap-2' : 'p-2 gap-3',
				)}
			>
				{tabs.map((tab) => (
					<TabItem
						key={tab.id}
						tab={tab}
						isDense={isDense}
						setPosition={setPosition}
						isDisabled={isLoading}
					/>
				))}
				<Cursor isDense={isDense} position={position} />
			</TabsList>
			{tabs.map((tab) => (
						<TabsContent key={tab.id} value={tab.id}>
							<AnimatePresence mode="wait">
								<motion.div
									key={tab.id}
									initial={{ y: 10, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ y: -10, opacity: 0 }}
									transition={{ duration: 0.2 }}
								>
									<div className={clsx(isDense ? "mt-3" : "mt-6")}>
										{!isLoading && (
											<>
												{children}
											</>
										)}
									</div>
								</motion.div>
							</AnimatePresence>
						</TabsContent>
					))}
		</RadixTabs>
	);
};

Tabs.displayName = "Tabs";
export default Tabs;
