export interface StatusCardProps {
	status: StatusCardStatus;
	variant?: StatusCardVariant;
	className?: string;
}

export enum StatusCardVariant {
	card = "Card",
	tile = "Tile",
}

export enum StatusCardStatus {
	operational = "Operational",
	disturbance = "Disturbance",
	maintenance = "Maintenance",
	unknown = "Unknown",
}
