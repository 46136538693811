import SectionWrapper from "@/components/ui/layout/sectionWrapper/sectionWrapper";
import { useScroll } from "framer-motion";
import { useRef } from "react";
import ControllingFeatureHero from "./components/controllingFeatureHero";
import ControllingFeatureImages from "./components/controllingFeatureImages";


const ControllingFeature: React.FC = () => { 
  const targetRef = useRef<HTMLDivElement | null>(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["-200px start", `end +400px`],
  });

	return (
		<SectionWrapper title={"Controlling"} subtitle={"Transparente Einblicke und Analysen um fundierte Entscheidungen zu treffen."}>
			<span className="text-lg">Behalten Sie Ihre Daten jederzeit im Blick! Mit unseren interaktiven Dashboards und detaillierten Charts gewinnen Sie wertvolle Einblicke in Ihre Geschäftsprozesse. Sämtliche Aktivitäten, Nutzungsstatistiken oder KPIs - unser Controlling-Tool liefert Ihnen die relevanten Kennzahlen in Echtzeit. Treffen Sie datengetriebene Entscheidungen und optimieren Sie Ihre Strategien für nachhaltigen Erfolg.</span>
      <section ref={targetRef} className="h-[500vh] mt-14 hidden md:block">
        <div className="lg:h-[calc(100vh-112px)] lg:min-h-[300px] sticky top-28 z-0 grid grid-cols-3 grid-rows-3 gap-4 p-4 overflow-hidden">
          <ControllingFeatureHero scrollYProgress={scrollYProgress} />
          <ControllingFeatureImages scrollYProgress={scrollYProgress} />
        </div>
      </section>
		</SectionWrapper>
	);
};

ControllingFeature.displayName = "ControllingFeature";
export default ControllingFeature;
