import { AnimatePresence, motion, useScroll, useSpring } from "framer-motion";
import { useState } from "react";
import {
	faAngleDown,
	faArrowRightToBracket,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNavbarData } from "../helpers/navbarData";
import type { MenuItem } from "../helpers/navbarTypes";
import { mobileNavbarAnimation, mobileNavbarMenuAnimation, mobileNavbarMenuItemAnimation, mobileNavbarTransition } from "../helpers/navbarAnimations";
import { Link } from "@tanstack/react-router";
import { ButtonIconSide } from "@/components/ui/button/helpers/buttonTypes";
import Button from "@/components/ui/button/button";
import MenuButton from "@/components/ui/menuButton/menuButton";
import { Icon } from "@/components/ui/icon/icon";
import { useNavbarState } from "@/store/navbarState";
import LanguageSelect from "@/components/ui/languageSelect/languageSelect";
import { appLanguages } from "@/lib/languages";
import * as t from "@/i18n/paraglide/messages";

const NavbarMobile: React.FC = () => {
	const [openMenuId, setOpenMenuId] = useState<MenuItem["id"] | null>(null);

	const isOpen = useNavbarState((state) => state.isOpen);

	const { scrollYProgress } = useScroll();

	const menu = getNavbarData();

	const windowScroll = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001,
	});

	const toggleFeature = (menuId: MenuItem["id"]) => {
		if (openMenuId === menuId) {
			setOpenMenuId(null);
		} else {
			setOpenMenuId(menuId);
		}
	};

	return (
		<>
			<div className="flex h-20 px-6 md:hidden">
				<div className="flex w-full content-center items-center justify-between">
					{/* Menu Button */}
					<div className="flex items-center">
						<MenuButton
							onClick={() => {
								useNavbarState.setState({ isOpen: !isOpen });
								setOpenMenuId(null);
							}}
							isOpen={isOpen}
						/>
					</div>
					{/* Actions */}
					<div className="flex gap-4">
						{/* Language */} 
						<LanguageSelect
							languages={appLanguages}
							withSearch={false}
							compact={true}
						/>
						{/* Login */}
						<Link to={"/login"}>
							<Button
								label={t.loginAction()}
								aria-label={t.loginAction()}
								icon={faArrowRightToBracket}
								iconSide={ButtonIconSide.Right}
							/>
						</Link>
					</div>
				</div>
			</div>
			{/* Scrollbar */}
			<motion.div
				className="fixed inset-x-0 h-px origin-left bg-primary"
				style={{ scaleX: windowScroll }}
			/>
			{/* Menu */}
			<AnimatePresence>
				{isOpen && (
					<motion.aside
						className="absolute mt-px flex h-[calc(100vh-5rem)] w-full flex-col flex-wrap justify-between bg-background px-6 pb-6 pt-4 md:hidden"
						initial="closed"
						animate="open"
						exit="closed"
						variants={mobileNavbarAnimation}
						transition={mobileNavbarTransition}
					>
						<div>
							{menu.items.map((menu) => {
								return (
									<motion.div
										key={menu.id}
										custom={menu.id}
										className="w-100 flex flex-col py-2 text-start"
										onClick={() => toggleFeature(menu.id)}
									>
										<div className="mb-3 mt-1 flex items-center justify-between border-b border-dashed border-blue-rinse-900 text-md">
											{menu.menuItems ? (
												<>
													<span className="font-bold">{menu.title}</span>
													<FontAwesomeIcon
														className={`transition-all duration-300 ease-in-out ${
															openMenuId === menu.id ? "rotate-180" : "rotate-0"
														}`}
														size={"sm"}
														icon={faAngleDown}
													/>
												</>
											) : (
												<Link href={menu.href}><span className="font-bold">{menu.title}</span></Link>
											)}
										</div>
										<AnimatePresence>
											{openMenuId === menu.id && (
												<motion.div
													initial="closed"
													animate="open"
													exit="closed"
													variants={mobileNavbarMenuAnimation}
													transition={{ duration: 0.3 }}
												>
													{menu.menuItems?.map((menuItem, j) => (
														<Link key={j} href={menuItem.href}>
															<motion.div
																custom={j}
																variants={mobileNavbarMenuItemAnimation}
																className="ml-4 flex items-center justify-start gap-3 py-3.5"
															>
																<Icon
																	fixedWidth
																	size={"lg"}
																	icon={menuItem.Icon}
																	primaryColor={menuItem.colors.primary}
																	secondaryColor={menuItem.colors.secondary}
																/>
																<span className="text-md opacity-80">
																	{menuItem.title}
																</span>
															</motion.div>
														</Link>
													))}
												</motion.div>
											)}
										</AnimatePresence>
									</motion.div>
								);
							})}
						</div>
						{/* <LegalLinks /> */}
					</motion.aside>
				)}
			</AnimatePresence>
		</>
	);
};

NavbarMobile.displayName = "NavbarMobile";
export default NavbarMobile;
