"use client";


import Button from "@/components/ui/button/button";
import { ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import { Input } from "@/components/ui/input/input";
import { faBellOn, faCalendarLines } from "@fortawesome/pro-light-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "@tanstack/react-router";

/**
 * Represents the application's desktop navbar.
 *
 * This component is designed to be used in the application layout. It encapsulates
 * the logic and presentation for the desktop navbar.
 *
 * @component
 * @returns {React.ReactElement} The desktop application's navbar.
 */
const NavbarDesktop: React.FC = () => {
	return (
		<>
			{/* Desktop */}
			<div className="hidden border-b px-2 sm:block sm:px-6 lg:px-10">
				<div className="relative flex h-20 items-center justify-between">
					<Input
						icon={faMagnifyingGlass}
						className="mr-3.5 max-w-lg"
						placeholder="Suche"
					/>
					<div className="flex gap-3.5">
						<Link to={"/"}>
							<Button variant={ButtonVariant.Outline} icon={faCalendarLines} />
						</Link>
						<Button
							variant={ButtonVariant.Outline}
							hasNotification={true}
							icon={faBellOn}
						/>
						{/*<AvatarSelect />*/}
					</div>
				</div>
			</div>
		</>
	);
};

export default NavbarDesktop;
