import { faUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import {
	getIconColor,
	getStatusCardBorderStyles,
	getStatusCardParentStyles,
	getStatusIcon,
} from "./helpers/statusCardStyles";
import {
	type StatusCardProps,
	StatusCardStatus,
	StatusCardVariant,
} from "./helpers/statusCardTypes";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";

const StatusCard: React.FC<StatusCardProps> = ({
	status = StatusCardStatus.unknown,
	variant = StatusCardVariant.card,
	className = "",
}) => {
	return (
		<div className={getStatusCardParentStyles({ status, variant, className })}>
			<div className="flex items-center gap-2.5">
				<Icon
					fixedWidth
					icon={getStatusIcon(status)}
					size={IconSize.Large}
					primaryColor={getIconColor({ status, isPrimaryColor: true })}
					secondaryColor={getIconColor({ status, isPrimaryColor: false })}
					className="opacity-80 duration-300 group-hover/card:opacity-100"
				/>
				{variant === StatusCardVariant.card && (
					<div className="flex flex-col">
						<span className="text-xs font-extralight">Status</span>
						<span className="text-xs font-semibold opacity-80 duration-300 group-hover/card:opacity-100">
							{status}
						</span>
					</div>
				)}
			</div>
			{variant === StatusCardVariant.card && (
				<FontAwesomeIcon
					fixedWidth
					icon={faUpRightFromSquare}
					size={IconSize.ExtraSmall}
					className="text-[#8d8f95] duration-300 group-hover/card:text-foreground"
				/>
			)}
			<span className={getStatusCardBorderStyles({ status })} />
		</div>
	);
};

StatusCard.displayName = "StatusCard";
export default StatusCard;
