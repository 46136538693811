import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FooterData, FooterTabData, FooterTabIds } from "./helpers/footerData";
import { Link } from "@tanstack/react-router";
import StatusCard from "@/components/ui/statusCard/statusCard";
import { StatusCardStatus } from "@/components/ui/statusCard/helpers/statusCardTypes";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { useState } from "react";
import { TabData } from "@/components/ui/tabs/helpers/tabTypes";
import Tabs from "@/components/ui/tabs/tabs";
import * as t from "@/i18n/paraglide/messages";

const Footer: React.FC = () => {
	const currentYear = new Date().getFullYear();
	const [activeTab, setActiveTab] = useState<TabData>(FooterTabData[0])

	return (
		<div className="mt-16">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
				<path
					fill="var(--background-tertiary)"
					fillOpacity="1"
					d="M0,32L80,48C160,64,320,96,480,96C640,96,800,64,960,58.7C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
				/>
			</svg>
			<footer className="bg-background-tertiary px-6" aria-labelledby="footer">
				<h2 id="footer-heading" className="sr-only">
					{/* ToDo */}
				</h2>
				<div className="mx-auto max-w-7xl pb-8 pt-14 sm:pt-24 lg:pt-20">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
						<div className="flex flex-col">
							<h3 className="leading-6 text-white">
								{t.resources()}
							</h3>
							<ul className="mt-5 space-y-4">
								{FooterData.resources.map((item) => (
									<li className="text" key={item.name}>
										<Link className="link" href={item.href}>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
						<div className="flex flex-col">
						<h3 className="leading-6 text-white">
								{t.company()}
							</h3>
							<ul className="mt-5 space-y-4">
								{FooterData.company.map((item) => (
									<li className="text-md" key={item.name}>
										<Link className="link" href={item.href} to={item.href}>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
						<div>
						<h3 className="leading-6 text-white">
								{t.legal()}
							</h3>
							<ul className="mt-5 space-y-4">
								{FooterData.legal.map((item) => (
									<li className="text-md" key={item.name}>
										<Link className="link" to={item.href}>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
						<div className="flex flex-col">
						<h3 className="leading-6 text-white mb-1.5">
								{t.download()}
							</h3>
							<div className="group relative flex cursor-pointer items-center self-stretch transition-colors hover:text-accent-foreground sm:self-auto sm:rounded-2xl">
									<Tabs
										tabs={FooterTabData}
										isDense
										activeTab={activeTab}
										handleClick={(tab) => {
											setActiveTab(tab);
										}}
									> 
										<>
											{activeTab.id === FooterTabIds.Apple && (
																				<div className="relative flex size-20 flex-none items-center justify-center">
												<div className="absolute inset-0 size-full stroke-foreground-secondary transition-colors duration-300 hover:stroke-primary">
												<svg viewBox="0 0 96 96" fill="none" aria-hidden="true">
													<path
														d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
														strokeWidth="2"
														strokeLinecap="round"
													/>
												</svg>
												{/* TODO: QR-Code 
												<Image
										src={QrCode}
										alt="QrCode App Store"
										unoptimized
										className="fill-foreground stroke-foreground text-foreground"
									/>*/}
												</div>
											</div>
											)}
											{activeTab.id === FooterTabIds.Android && (
																				<div className="relative flex size-20 flex-none items-center justify-center">
												<div className="absolute inset-0 size-full stroke-foreground-secondary transition-colors duration-300 hover:stroke-primary">
												<svg viewBox="0 0 96 96" fill="none" aria-hidden="true">
													<path
														d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
														strokeWidth="2"
														strokeLinecap="round"
													/>
												</svg>
												{/* TODO: QR-Code 
												<Image
										src={QrCode}
										alt="QrCode App Store"
										unoptimized
										className="fill-foreground stroke-foreground text-foreground"
									/>*/}
												</div>
											</div>
											)}
										</>
									</Tabs>
								</div>
						</div>
					</div>
					<div className="flex gap-4 md:gap-0 md:items-center lg:mt-18 md:mt-14 mt-6 border-t border-white/10 pt-6 sm:mt-20 md:flex-row flex-col md:justify-between">
							<StatusCard
								status={StatusCardStatus.operational}
								className="h-11 w-full md:w-40 border-white/10"
							/>
							<div className="pl-1 md:pl-0">
							<span className="font-thin leading-5 text-sm md:order-1 md:mt-0 flex">
								&copy; {currentYear} Koyo Cloud
							</span>
						</div>
							<div className="flex space-x-6 md:order-2">
								{FooterData.social.map((item) => (
									<a
										key={item.name}
										href={item.href}
										className="transition duration-300 text-foreground-tertiary hover:text-foreground-secondary"
									>
										<span className="sr-only">{item.name}</span>
										<FontAwesomeIcon
											fixedWidth
											icon={item.Icon}
											size={IconSize.ExtraLarge}
											aria-hidden={true}
										/>
									</a>
								))}
							</div>
						</div>
				</div>
			</footer>
		</div>
	);
};

Footer.displayName = "Footer";
export default Footer;
