import * as t from "@/i18n/paraglide/messages";
import type { Benefit } from "./pricingTypes";

export const FreePlan: Benefit[] = [
	{ text: `1-5 ${t.user()}`, checked: true },
	{ text: "20 GB Speicherplatz", checked: true },
	{ text: "TBD 3", checked: false },
	{ text: "TBD 4", checked: false },
	{ text: "TBD 5", checked: false },
	{ text: "TBD 6", checked: false },
];

export const ProPlan: Benefit[] = [
	{ text: `5-15 ${t.user()}`, checked: true },
	{ text: "100 GB Speicherplatz", checked: true },
	{ text: "TBD 3", checked: true },
	{ text: "TBD 4", checked: true },
	{ text: "TBD 5", checked: false },
	{ text: "TBD 6", checked: false },
];

export const EnterprisePlan: Benefit[] = [
	{ text: `∞ ${t.user()}`, checked: true },
	{ text: "∞ Speicherplatz", checked: true },
	{ text: "TBD 3", checked: true },
	{ text: "TBD 4", checked: true },
	{ text: "TBD 5", checked: true },
	{ text: "TBD 6", checked: true },
];
