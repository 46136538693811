import {
	faFile,
	faImage,
	faInputText,
	faListCheck,
	faListDropdown,
	faListRadio,
	faSignature,
	faTextSize,
	faToggleOn,
} from "@fortawesome/pro-duotone-svg-icons";
import type { FormComponent } from "./formFeatureComponentGridTypes";

export const FormComponents: FormComponent[] = [
	{
		title: "Eingabefelder",
		description:
			"Belegen Sie Eingabefelder mit Validierungen um Texte, Zahlen oder e-Mail Adressen zu erfassen.",
		icon: faInputText,
		colors: {
			primary: "brescian-blue",
			secondary: "brescian-blue-8",
			tertiary: "brescian-blue-1",
		},
	},
	{
		title: "Schalter",
		description:
			"",
		icon: faToggleOn,
		colors: {
			primary: "ineffable-green-9",
			secondary: "ineffable-green-8",
			tertiary: "ineffable-green-1",
		},
	},
	{
		title: "Radio",
		description:
			"",
		icon: faListRadio,
		colors: {
			primary: "queer-purple",
			secondary: "queer-purple-8",
			tertiary: "queer-purple-1",
		},
	},
	{
		title: "Checkbox",
		description:
			"Ermöglichen Sie eine mehrfache Auswahl von Optionen mit Checkboxen.",
		icon: faListCheck,
		colors: {
			primary: "pumpkin-orange-9",
			secondary: "pumpkin-orange-8",
			tertiary: "pumpkin-orange-1",
		},
	},
	{
		title: "Text Editor",
		description:
			"Das Textfeld ist ein mehrzeiliges Eingabefeld für längere Texte. Ideal für Kommentare oder Feedback.",
		icon: faTextSize,
		colors: {
			primary: "fuchsia-red",
			secondary: "fuchsia-red-8",
			tertiary: "fuchsia-red-1",
		},
	},
	{
		title: "Bilder",
		description:
			"Zeigen Sie Bilder in Ihren Formularen, um visuelle Informationen zu vermitteln.",
		icon: faImage,
		colors: {
			primary: "sea-green",
			secondary: "sea-green-8",
			tertiary: "sea-green-1",
		},
	},
	{
		title: "Dropdowns",
		description:
			"Bieten Sie eine Liste von Optionen zur Auswahl mit der Möglichkeit die Optionen zu durchsuchen.",
		icon: faListDropdown,
		colors: {
			primary: "viola",
			secondary: "viola-8",
			tertiary: "viola-1",
		},
	},
	{
		title: "Dateien",
		description:
			"Binden Sie PDF-Dokumente in Ihre Formulare ein, um Zugriff auf wichtige Dokumente zu ermöglichen.",
		icon: faFile,
		colors: {
			primary: "khaki",
			secondary: "khaki-8",
			tertiary: "khaki-1",
		},
	},
	{
		title: "Signatur",
		description:
			"Ein digitales Zeichenfeld für Unterschriften um Vereinbarungen und Verträge zu unterzeichnen.",
		icon: faSignature,
		colors: {
			primary: "flamingo-red",
			secondary: "flamingo-red-8",
			tertiary: "flamingo-red-1",
		},
	},
];
