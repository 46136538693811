import type { AnimationProps } from "framer-motion";

export interface SpinningLogoDataProps {
	image: string;
	link: string;
	className: string;
}

export interface SpinningLogoSize {
	width: number | undefined;
	height: number | undefined;
}

// Defines the distance from the center of the circle to the center
// of the icons
export const SpinningLogosIconCenter = {
	sm: 200,
	md: 325,
	lg: 425,
};
// Defines the width of the icon circles
export const SpinningLogosIconWrapper = {
	sm: 40,
	md: 65,
	lg: 80,
};
// Defines the padding between the icon circles and the inner and outer rings
export const SpinningLogosPadding = {
	sm: 4,
	md: 8,
	lg: 12,
};
// Defines the font spinninglogosize for logos
export const SpinningLogosFontSize = {
	sm: 18,
	md: 24,
	lg: 36,
};

export const SpinningLogosBreakpoint = {
	sm: 480,
	md: 768,
};

export const SpinningLogosTransition: AnimationProps["transition"] = {
	repeat: Number.POSITIVE_INFINITY,
	repeatType: "loop",
	duration: 50,
	ease: "linear",
};
