import { useScroll } from "framer-motion";
import { useRef } from "react";
import AppFeatureCarouselCard from "./appFeatureCarouselCard";

const AppFeatureCarousel: React.FC = () => {
	const ref = useRef(null);
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ["-160px start", "end start"],
	});

	return (
		<div className="relative w-full">
			<div className="sticky top-0 z-10 hidden h-24 w-full md:block" />
			<div ref={ref} className="relative z-0 flex flex-col gap-6 md:gap-12">
				<AppFeatureCarouselCard
					scrollYProgress={scrollYProgress}
					position={1}
					numItems={4}
				/>
				<AppFeatureCarouselCard
					scrollYProgress={scrollYProgress}
					position={2}
					numItems={4}
				/>
				<AppFeatureCarouselCard
					scrollYProgress={scrollYProgress}
					position={3}
					numItems={4}
				/>
			</div>
		</div>
	);
};

AppFeatureCarousel.displayName = "AppFeatureCarousel";
export default AppFeatureCarousel;
