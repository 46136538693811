import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	animate,
	motion,
	useMotionTemplate,
	useMotionValue,
} from "framer-motion";
import type React from "react";
import { useEffect, useRef } from "react";

const NewsletterForm: React.FC = () => {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const turn = useMotionValue(0);

	useEffect(() => {
		animate(turn, 1, {
			ease: "linear",
			duration: 5,
			repeat: Number.POSITIVE_INFINITY,
		});
	}, [turn]);

	const backgroundImage = useMotionTemplate`conic-gradient(from ${turn}turn,  #3b58e800 75%, #3b58e8 100%)`;

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
			}}
			onClick={() => {
				inputRef.current?.focus();
			}}
			className="relative flex w-full max-w-md items-center gap-2 rounded-md border border-background-secondary bg-background-tertiary py-1.5 pl-6 pr-1.5"
		>
			<input
				ref={inputRef}
				type="email"
				placeholder="e-Mail"
				className="w-full bg-transparent placeholder:text-foreground"
			/>

			<button
				onClick={(e) => e.stopPropagation()}
				type="submit"
				className="group flex shrink-0 items-center gap-1.5 rounded-md bg-primary px-4 py-3 text-sm font-medium  transition-transform active:scale-[0.985]"
			>
				<span>Anmelden</span>
				<FontAwesomeIcon
					icon={faArrowRight}
					className="-mr-4 opacity-0 transition-all group-active:-rotate-45 group-hover:-mr-0 group-hover:opacity-100"
				/>
			</button>

			<div className="pointer-events-none absolute inset-0 z-10 rounded-md">
				<motion.div
					style={{
						backgroundImage,
					}}
					className="mask-with-browser-support absolute -inset-px rounded-md border border-transparent bg-origin-border"
				/>
			</div>
		</form>
	);
};

NewsletterForm.displayName = "NewsletterForm";
export default NewsletterForm;
