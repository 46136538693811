import parse from "html-react-parser";
import { FadeInStaggerWrapper, FadeInWrapper } from "../fadeInWrapper/fadeInWrapper";
import { SectionWrapperProps } from "./helpers/sectionWrapperTypes";
import { Separator } from "../../separator/separator";


const SectionWrapper: React.FC<SectionWrapperProps> = ({
	title,
	ankerId,
	ref,
	subtitle,
	children,
}) => {
	return (
		<section id={ankerId} ref={ref}>
			<FadeInStaggerWrapper className="flex flex-col pt-16 md:pt-32 pb-6 md:pb-12 p-6 md:gap-0 gap-5">
				<FadeInWrapper>
					<div className="relative flex flex-col items-center justify-center">
						<div className="flex flex-col items-center justify-center gap-2">
							<span className="text-4xl md:text-[2.5rem] ">{title}</span>
							{subtitle && (
								<span className="text-foreground-secondary text-lg  md:text-[1.2rem]">
									{parse(subtitle)}
								</span>
							)}
						</div>
						<Separator className="hidden md:flex w-32 self-center my-7" />
					</div>
				</FadeInWrapper>
				<FadeInWrapper>{children}</FadeInWrapper>
			</FadeInStaggerWrapper>
		</section>
	);
};

SectionWrapper.displayName = "SectionWrapper";
export default SectionWrapper;
