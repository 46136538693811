import type { SpinningLogoDataProps } from "./spinningLogosTypes";

export const SpinningLogoData: SpinningLogoDataProps[] = [
	{
		image: "/images/logos/flowcraftdbLogo.webp",
		link: "https://www.flowcraftdb.de/",
		className: "",
	},
	{
		image: "/images/logos/microsoftLogo.webp",
		link: "https://www.microsoft.com/dynamics-365",
		className: "",
	},
	{
		image: "/images/logos/SalesforceLogo.webp",
		link: "",
		className: "",
	},
	{
		image: "/images/logos/copperLogo.webp",
		link: "https://www.copper.com/",
		className: "",
	},
	{
		image: "/images/logos/hubspotLogo.webp",
		link: "",
		className: "",
	},
	{
		image: "",
		link: "",
		className: "",
	},
	{
		image: "",
		link: "",
		className: "",
	},
];
