import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { NavbarDesktopMenuContentProps } from "../../helpers/navbarTypes";
import { getNavbarData } from "../../helpers/navbarData";
import { HoverCardVariant } from "@/components/ui/hoverCard/helpers/hoverCardProps";
import HoverCard from "@/components/ui/hoverCard/hoverCard";

/**
 * NavbarDesktopMenuContent component renders the content of the desktop menu in the navbar.
 * It animates the appearance and disappearance of the menu content and handles the positioning
 * of a visual indicator (nub) based on the selected menu item.
 *
 * @component
 * @param {NavbarDesktopMenuContentProps} props - The properties for the component.
 * @param {Object} props.selectedMenuItem - The currently selected menu item.
 * @param {string} props.dir - The direction of the animation for the menu items.
 *
 * @returns {JSX.Element} The rendered NavbarDesktopMenuContent component.
 */
const NavbarDesktopMenuContent: React.FC<NavbarDesktopMenuContentProps> = ({
	selectedMenuItem,
	dir,
}) => {
	const [left, setLeft] = useState(0);

	useEffect(() => {
		moveNub();
	});

	const menu = getNavbarData();

	const moveNub = () => {
		if (selectedMenuItem) {
			const hoveredTab = document.getElementById(
				`shift-tab-${selectedMenuItem.id}`,
			);
			const overlayContent = document.getElementById("overlay-content");

			if (!hoveredTab || !overlayContent) return;

			const tabRect = hoveredTab.getBoundingClientRect();
			const { left: contentLeft } = overlayContent.getBoundingClientRect();

			const tabCenter = tabRect.left + tabRect.width / 2 - contentLeft;

			setLeft(tabCenter);
		}
	};

	return (
		<motion.div
			id="overlay-content"
			initial={{
				opacity: 0,
				y: 8,
			}}
			animate={{
				opacity: 1,
				y: 0,
			}}
			exit={{
				opacity: 0,
				y: 8,
			}}
			className="z-50 absolute left-0 top-[calc(100%_+_10px)] rounded-lg border bg-background-secondary"
		>
			<div className="absolute -top-[24px] left-0 right-0 h-[24px]" />
			<motion.span
				style={{
					clipPath: "polygon(0 0, 100% 0, 50% 50%, 0% 100%)",
				}}
				animate={{ left }}
				transition={{ duration: 0.25, ease: "easeInOut" }}
				className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-tl border bg-background-secondary"
			/>
			{menu.items.map((t) => {
				return (
					<div className="overflow-hidden" key={t.id}>
						{selectedMenuItem?.id === t.id && (
							<motion.div
								initial={{
									opacity: 0,
									x: dir === "l" ? 100 : dir === "r" ? -100 : 0,
								}}
								animate={{ opacity: 1, x: 0 }}
								transition={{ duration: 0.25, ease: "easeInOut" }}
							>
								<ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
									{t.menuItems?.map((menuItem, index) => (
										<HoverCard
											key={index}
											title={menuItem.title}
											description={menuItem.description}
											icon={menuItem.Icon}
											variant={HoverCardVariant.Borderless}
											colors={{
												primary: menuItem.colors.primary,
												secondary: menuItem.colors.secondary,
												tertiary: menuItem.colors.tertiary,
											}}
											href={menuItem.href}
											hrefHash={menuItem.hash}
										/>
									))}
								</ul>
							</motion.div>
						)}
					</div>
				);
			})}
		</motion.div>
	);
};

NavbarDesktopMenuContent.displayName = "NavbarDesktopMenuContent";
export default NavbarDesktopMenuContent;
