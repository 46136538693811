import { motion } from "framer-motion";
import type { CursorProps } from "../helpers/tabTypes";
import clsx from "clsx";

const Cursor: React.FC<CursorProps> = ({ position, isDense = false }) => {
	return (
		<motion.span
			animate={{
				...position,
			}}
			className={clsx("absolute z-0 rounded-md bg-background-tertiary h-4", 
				isDense ? "md:h-7" : "md:h-9",
			)}
		/>
	);
};

Cursor.displayName = "Cursor";
export default Cursor;
