import { Outlet, createRootRoute } from '@tanstack/react-router'

import "unfonts.css"
import "@/styles/globals.css";
import Providers from '@/providers/providers';

export const Route = createRootRoute({
  component: RootComponent,
})

function RootComponent() {
  return (
    <Providers>
      <Outlet />
    </Providers>
  )
}
