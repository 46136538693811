import type React from "react";
import { IFlagIcon } from "./helpers/flagTypes";

const FlagES: React.FC<IFlagIcon> = ({ height }) => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			enableBackground="new 0 0 512 512"
			xmlSpace="preserve"
			width={`${height}rem`}
			height={`${height}rem`}
		>
			<g>
				<path
					fill="#C8414B"
					d="M0,385.379c0,21.177,17.167,38.345,38.345,38.345h435.31c21.177,0,38.345-17.167,38.345-38.345
      v-32.276H0V385.379z"
				/>
				<path
					fill="#C8414B"
					d="M473.655,88.276H38.345C17.167,88.276,0,105.443,0,126.621v32.276h512v-32.276
      C512,105.443,494.833,88.276,473.655,88.276z"
				/>
			</g>
			<rect y="158.9" fill="#FFD250" width="512" height="194.21" />
			<path
				fill="#C8414B"
				d="M216.276,256l7.485-33.681c0.69-3.102-1.671-6.044-4.849-6.044h-5.272
    c-3.177,0-5.537,2.943-4.849,6.044L216.276,256z"
			/>
			<rect
				x="207.45"
				y="238.34"
				fill="#F5F5F5"
				width="17.655"
				height="75.03"
			/>
			<rect
				x="203.03"
				y="229.52"
				fill="#FAB446"
				width="26.483"
				height="8.828"
			/>
			<g>
				<rect x="185.38" y="256" fill="#C8414B" width="44.14" height="8.828" />
				<polygon
					fill="#C8414B"
					points="229.517,291.31 203.034,282.483 203.034,273.655 229.517,282.483 	"
				/>
				<path
					fill="#C8414B"
					d="M83.862,256l7.485-33.681c0.69-3.102-1.671-6.044-4.849-6.044h-5.272
      c-3.177,0-5.537,2.943-4.849,6.044L83.862,256z"
				/>
			</g>
			<path
				fill="#F5F5F5"
				d="M114.759,229.517c-4.875,0-8.828,3.953-8.828,8.828v57.379c0,10.725,10.01,30.897,44.138,30.897
    s44.138-20.171,44.138-30.897v-57.379c0-4.875-3.953-8.828-8.828-8.828H114.759z"
			/>
			<g>
				<path
					fill="#C8414B"
					d="M150.069,273.655h-44.138v-35.31c0-4.875,3.953-8.828,8.828-8.828h35.31V273.655z"
				/>
				<path
					fill="#C8414B"
					d="M150.069,273.655h44.138v22.069c0,12.189-9.88,22.069-22.069,22.069l0,0
      c-12.189,0-22.069-9.88-22.069-22.069V273.655z"
				/>
			</g>
			<path
				fill="#FAB446"
				d="M105.931,273.655h44.138v22.069c0,12.189-9.88,22.069-22.069,22.069l0,0
    c-12.189,0-22.069-9.88-22.069-22.069V273.655z"
			/>
			<g>
				<path
					fill="#C8414B"
					d="M141.241,313.28v-39.625h-8.828v43.693C135.697,316.682,138.664,315.228,141.241,313.28z"
				/>
				<path
					fill="#C8414B"
					d="M123.586,317.348v-43.693h-8.828v39.625C117.336,315.228,120.303,316.682,123.586,317.348z"
				/>
			</g>
			<rect x="114.76" y="256" fill="#FFB441" width="26.483" height="8.828" />
			<g>
				<rect
					x="114.76"
					y="238.34"
					fill="#FAB446"
					width="26.483"
					height="8.828"
				/>
				<rect
					x="119.17"
					y="243.59"
					fill="#FAB446"
					width="17.655"
					height="15.992"
				/>
			</g>
			<rect x="75.03" y="238.34" fill="#F5F5F5" width="17.655" height="75.03" />
			<g>
				<rect
					x="70.62"
					y="308.97"
					fill="#FAB446"
					width="26.483"
					height="8.828"
				/>
				<rect
					x="70.62"
					y="229.52"
					fill="#FAB446"
					width="26.483"
					height="8.828"
				/>
			</g>
			<rect x="66.21" y="317.79" fill="#5064AA" width="35.31" height="8.828" />
			<rect
				x="207.45"
				y="308.97"
				fill="#FAB446"
				width="26.483"
				height="8.828"
			/>
			<rect x="198.62" y="317.79" fill="#5064AA" width="35.31" height="8.828" />
			<rect
				x="123.59"
				y="220.69"
				fill="#FAB446"
				width="52.966"
				height="8.828"
			/>
			<rect
				x="145.66"
				y="194.21"
				fill="#FFB441"
				width="8.828"
				height="26.483"
			/>
			<g>
				<path
					fill="#F5F5F5"
					d="M141.241,207.448c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
      c7.302,0,13.241,5.94,13.241,13.241C154.483,201.508,148.543,207.448,141.241,207.448z M141.241,189.793
      c-2.435,0-4.414,1.978-4.414,4.414c0,2.435,1.978,4.414,4.414,4.414s4.414-1.978,4.414-4.414
      C145.655,191.772,143.677,189.793,141.241,189.793z"
				/>
				<path
					fill="#F5F5F5"
					d="M158.897,207.448c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
      c7.302,0,13.241,5.94,13.241,13.241S166.198,207.448,158.897,207.448z M158.897,189.793c-2.435,0-4.414,1.978-4.414,4.414
      c0,2.435,1.978,4.414,4.414,4.414c2.435,0,4.414-1.978,4.414-4.414C163.31,191.772,161.332,189.793,158.897,189.793z"
				/>
				<path
					fill="#F5F5F5"
					d="M176.552,216.276c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
      c7.302,0,13.241,5.94,13.241,13.241S183.853,216.276,176.552,216.276z M176.552,198.621c-2.435,0-4.414,1.978-4.414,4.414
      c0,2.435,1.978,4.414,4.414,4.414c2.435,0,4.414-1.978,4.414-4.414S178.987,198.621,176.552,198.621z"
				/>
				<path
					fill="#F5F5F5"
					d="M123.586,216.276c-7.302,0-13.241-5.94-13.241-13.241c0-7.302,5.94-13.241,13.241-13.241
      c7.302,0,13.241,5.94,13.241,13.241C136.828,210.336,130.888,216.276,123.586,216.276z M123.586,198.621
      c-2.435,0-4.414,1.978-4.414,4.414c0,2.435,1.978,4.414,4.414,4.414S128,205.47,128,203.034
      C128,200.599,126.022,198.621,123.586,198.621z"
				/>
			</g>
			<path
				fill="#FAB446"
				d="M176.552,291.31v4.414c0,2.434-1.98,4.414-4.414,4.414s-4.414-1.98-4.414-4.414v-4.414H176.552
    M185.379,282.483h-26.483v13.241c0,7.302,5.94,13.241,13.241,13.241c7.302,0,13.241-5.94,13.241-13.241v-13.241H185.379z"
			/>
			<path
				fill="#FFA0D2"
				d="M172.138,264.828L172.138,264.828c-4.875,0-8.828-3.953-8.828-8.828v-8.828
    c0-4.875,3.953-8.828,8.828-8.828l0,0c4.875,0,8.828,3.953,8.828,8.828V256C180.966,260.875,177.013,264.828,172.138,264.828z"
			/>
			<circle fill="#5064AA" cx="150.07" cy="273.65" r="13.241" />
			<rect
				x="145.66"
				y="176.55"
				fill="#FAB446"
				width="8.828"
				height="26.483"
			/>
			<path
				fill="#C8414B"
				d="M123.586,220.69l-8.828-8.828l5.171-5.171c7.993-7.993,18.835-12.484,30.14-12.484l0,0
    c11.305,0,22.146,4.491,30.14,12.484l5.171,5.171l-8.828,8.828H123.586z"
			/>
			<g>
				<circle fill="#FFD250" cx="150.07" cy="211.86" r="4.414" />
				<circle fill="#FFD250" cx="132.41" cy="211.86" r="4.414" />
				<circle fill="#FFD250" cx="167.72" cy="211.86" r="4.414" />
			</g>
			<g>
				<rect x="70.62" y="256" fill="#C8414B" width="44.14" height="8.828" />
				<polygon
					fill="#C8414B"
					points="70.621,291.31 97.103,282.483 97.103,273.655 70.621,282.483 	"
				/>
			</g>
		</svg>
	);
};

export default FlagES;
