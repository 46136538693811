import SectionWrapper from "@/components/ui/layout/sectionWrapper/sectionWrapper";
import AppFeatureCarousel from "./components/appFeatureCarousel";


const AppFeature: React.FC = () => {
	return (
		<SectionWrapper title={"App"} subtitle={"Subtitle"}>
			<div className="relative h-fit">
				<div className="relative mx-auto grid size-full max-w-7xl grid-cols-1 gap-8 md:grid-cols-2">
					<div className="flex h-fit w-full flex-col items-center justify-center py-24 md:sticky md:top-60">
						<p className="text-lg">
							Lorem ipsum dolor sit amet consectetur. Dolor quis a leo lobortis
							orci tortor eget. Enim proin aliquam nulla a lacus pellentesque
							quam in. Nec vel vel nulla nunc vel in molestie proin convallis.
							Leo et vulputate tincidunt justo a varius et elementum.
						</p>
					</div>
					<AppFeatureCarousel />
				</div>
			</div>
		</SectionWrapper>
	);
};

AppFeature.displayName = "AppFeature";
export default AppFeature;
