import * as t from "@/i18n/paraglide/messages";
import {
	faAnalytics,
	faCircleNodes,
	faImage,
	faRectangleList,
	faTabletScreen,
} from "@fortawesome/pro-duotone-svg-icons";
import type { Feature } from "./featureTypes";

export const FeatureData: Feature[] = [
	{
		id: 1,
		title: t.forms(),
		Icon: faRectangleList,
		href: "/features",
		hash: "forms",
		colors: {
			primary: "brescian-blue",
			secondary: "brescian-blue-8",
			tertiary: "brescian-blue-1",
		},
		description: t.formsDescriptionLong(),
		image: "placeholder.webp",
	},
	{
		id: 2,
		title: t.content(),
		Icon: faImage,
		href: "/features",
		hash: "content",
		colors: {
			primary: "ineffable-green-9",
			secondary: "ineffable-green-8",
			tertiary: "ineffable-green-1",
		},
		description: t.contentDescriptionLong(),
		image: "placeholder.webp",
	},
	{
		id: 3,
		title: t.app(),
		Icon: faTabletScreen,
		href: "/features",
		hash: "client",
		colors: {
			primary: "radiance-blue-9",
			secondary: "radiance-blue-8",
			tertiary: "radiance-blue-1",
		},
		description: t.appDescriptionLong(),
		image: "placeholder.webp",
	},
	{
		id: 5,
		title: t.controlling(),
		Icon: faAnalytics,
		href: "/features",
		hash: "controlling",
		colors: {
			primary: "pumpkin-orange-9",
			secondary: "pumpkin-orange-8",
			tertiary: "pumpkin-orange-1",
		},
		description: t.controllingDescriptionLong(),
		image: "placeholder.webp",
	},
	{
		id: 4,
		title: t.connectivity(),
		isEnterprise: true,
		Icon: faCircleNodes,
		href: "/features",
		hash: "connectivity",
		colors: {
			primary: "queer-purple-9",
			secondary: "queer-purple-8",
			tertiary: "queer-purple-1",
		},
		description: t.connectivityDescriptionLong(),
		image: "placeholder.webp",
	},
];
