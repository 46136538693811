/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const appDescriptionLong = () => `Greifen Sie auf Ihre Formulare und Content bequem von jedem Smartphone oder Tablet aus zu, und passen Sie die App nahtlos an Ihr Corporate Design mit Ihrem Logo und individuellen Farben an.`
