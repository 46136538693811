import { create } from "zustand";

interface SidebarState {
	activePage: Page;
}

export enum Page {
	Dashboard = "Dashboard",
	Events = "Events",
	Leads = "Leads",
	Forms = "Forms",
	Content = "Content",
	Client = "Client",
}

export const useSidebarState = create<SidebarState>()(() => ({
	activePage: Page.Dashboard,
}));
