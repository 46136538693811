import {
	faAndroid,
	faApple,
	faLinkedin,
	faSquareYoutube,
	faSquareXTwitter
} from "@fortawesome/free-brands-svg-icons";
import * as t from "@/i18n/paraglide/messages";
import { TabData } from "@/components/ui/tabs/helpers/tabTypes";

export const FooterData = {
	resources: [
		{ name: t.status(), href: "#" },
		{ name: t.changelog(), href: "/changelog" },
	],
	company: [
		{ name: t.contact(), href: "/contact" },
		{ name: t.career(), href: "/career" },
	],
	legal: [
		{ name: t.legalNotice(), href: "/legal-notice" },
		{ name: t.termsOfService(), href: "/terms-of-service" },
		{ name: t.privacyPolicy(), href: "/privacy-policy" },
	],
	social: [
		{
			name: "X",
			href: "#",
			Icon: faSquareXTwitter,
		},
		{
			name: "YouTube",
			href: "#",
			Icon: faSquareYoutube,
		},
		{
			name: "LinkedIn",
			href: "#",
			Icon: faLinkedin,
		},
	],
};

export enum FooterTabIds {
	Apple = "apple",
	Android = "android",
}

export const FooterTabData: TabData[] = [
	{
		id: FooterTabIds.Apple,
		name: "iOS",
		Icon: faApple,
	},
	{
		id: FooterTabIds.Android,
		name: "Android",
		Icon: faAndroid,
	},
];