import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import * as React from "react";

import { cn } from "@/lib/utils";
import { IconSize } from "../icon/helpers/iconTypes";
import { Icon } from "../icon/icon";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	icon?: IconDefinition;
	isDense?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, icon, isDense = false, ...props }, ref) => {
		return (
			<div className="w-100 relative flex grow items-center">
				{icon && (
					<div className="absolute left-2 top-1/2 -translate-y-1/2">
						<Icon
							fixedWidth
							size={isDense ? IconSize.ExtraSmall : IconSize.Medium}
							icon={icon}
						/>
					</div>
				)}
				<input
					type={type}
					className={cn(
						"flex h-10 w-full rounded-md border border-input bg-background-tertiary px-3 py-2 text-sm transition duration-300 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground active:border-border-hover hover:border-border-hover focus:border-border-hover  focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
						icon ? "pl-8" : "",
						className,
					)}
					ref={ref}
					{...props}
				/>
			</div>
		);
	},
);

Input.displayName = "Input";
export { Input };
