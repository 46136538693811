import type React from "react";
import { IFlagIcon } from "./helpers/flagTypes";

const FlagFR: React.FC<IFlagIcon> = ({ height }) => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			enableBackground="new 0 0 512 512"
			xmlSpace="preserve"
			width={`${height}rem`}
			height={`${height}rem`}
		>
			<path
				fill="#41479B"
				d="M38.345,88.273C17.167,88.273,0,105.44,0,126.618v258.759c0,21.177,17.167,38.345,38.345,38.345
      h132.322V88.273H38.345z"
			/>
			<rect
				x="170.67"
				y="88.277"
				fill="#F5F5F5"
				width="170.67"
				height="335.45"
			/>
			<path
				fill="#FF4B55"
				d="M473.655,88.273H341.333v335.448h132.322c21.177,0,38.345-17.167,38.345-38.345V126.618
      C512,105.44,494.833,88.273,473.655,88.273z"
			/>
		</svg>
	);
};

export default FlagFR;
