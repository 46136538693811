import { motion, useTransform } from "framer-motion";
import { ControllingFeatureHeroProps } from "../helpers/contentFeatureTypes";


const ControllingFeatureHero: React.FC<ControllingFeatureHeroProps> = ({
  scrollYProgress,
}) => {
  const heroScale = useTransform(scrollYProgress, [0, 0.75], [1, 0.5]);
  const heroOpacity = useTransform(scrollYProgress, [0, 0.75], [1, 0]);
  const heroY = useTransform(scrollYProgress, [0, 0.75], ["0%", "7.5%"]);

  return (
    <motion.div
      style={{
        scale: heroScale,
        opacity: heroOpacity,
        y: heroY,
      }}
      className="absolute px-8 w-full h-screen z-20 flex flex-col items-center justify-center"
    >
      <h1 className="text-lg md:text-[2.85rem] tracking-wider font-semibold text-center max-w-xl leading-relaxed">
      Daten im Griff - < br/>Entscheidungen im Blick. 
      </h1>
    </motion.div>
  );
};


export default ControllingFeatureHero;