import type { ReactNode } from "react";

export interface Benefit {
	text: string;
	checked: boolean;
}

export type PriceCardProps = {
	tier: string;
	price: string;
	billingPeriod: BillingPeriod;
	pricePeriod?: string;
	bestFor: string;
	Button: ReactNode;
	benefits: Benefit[];
};

export enum BillingPeriod {
	Monthly = "monthly",
	Annually = "annually",
}

export interface PricingToggleProps {
	billingPeriod: BillingPeriod;
	setBillingPeriod: (period: BillingPeriod) => void;
}
