import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import ComponentCard from "./components/formFeatureComponentCard";
import { FormComponents } from "./helpers/formFeatureComponentGridData";

const FormComponentGrid: React.FC = () => {
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

	return (
		<div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
			{FormComponents.map((item, idx) => (
				<div
					key={idx}
					className="relative group block p-2 size-full"
					onMouseEnter={() => setHoveredIndex(idx)}
					onMouseLeave={() => setHoveredIndex(null)}
				>
					<AnimatePresence>
						{hoveredIndex === idx && (
							<motion.span
								className="absolute inset-0 size-full bg-primary block rounded-3xl"
								layoutId="hoverBackground"
								initial={{ opacity: 0 }}
								animate={{
									opacity: 1,
									transition: { duration: 0.15 },
								}}
								exit={{
									opacity: 0,
									transition: { duration: 0.15, delay: 0.2 },
								}}
							/>
						)}
					</AnimatePresence>
					<ComponentCard {...item} />
				</div>
			))}
		</div>
	);
};

FormComponentGrid.displayName = "FormComponentGrid";
export default FormComponentGrid;
