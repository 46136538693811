import LoginScreen from '@/components/screens/landingPage/auth/login/loginScreen'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import * as t from '@/i18n/paraglide/messages';

const loginScreenSchema = z.object({
  verificationSuccess: z.coerce.boolean().optional(),
})

export const Route = createFileRoute('/_landingPageLayout/(auth)/login')({
  component: LoginScreen,
  validateSearch: zodSearchValidator(loginScreenSchema),
  beforeLoad: () => {
      document.title = `${import.meta.env.VITE_APP_NAME} • ${t.loginTitle()}`
    }
})
