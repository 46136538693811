import { createContext, useContext, useEffect, useState } from "react";
import { type AvailableLanguageTag, setLanguageTag, languageTag, sourceLanguageTag } from "@/i18n/paraglide/runtime";
import { useRouter } from "@tanstack/react-router";

interface LanguageContextType {
	locale: AvailableLanguageTag;
	setLocale: (locale: AvailableLanguageTag) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const router = useRouter()
	const [locale, setLocale] = useState<AvailableLanguageTag>(() => {
		// Initialize from localStorage or default to browser language
		const savedLocale = localStorage.getItem("koyo_locale");
    console.log("localeStorage", savedLocale)
    console.log("navigator", navigator.language.split("-")[0] as AvailableLanguageTag)
    const languageTag = savedLocale ? (savedLocale as AvailableLanguageTag) : (navigator.language.split("-")[0] as AvailableLanguageTag);
    setLanguageTag(languageTag)
    document.documentElement.lang = languageTag;
    return languageTag;
	});

	useEffect(() => {
		document.documentElement.lang = locale;
		localStorage.setItem("koyo_locale", locale);
    setLanguageTag(locale);
	}, [locale]);


	return (
		<LanguageContext.Provider value={{ locale, setLocale }}>
			{children}
		</LanguageContext.Provider>
	);
};

export const useLanguage = () => {
	const context = useContext(LanguageContext);
	if (!context) {
		throw new Error("useLanguage must be used within a LanguageProvider");
	}
	return context;
};
