import * as t from "@/i18n/paraglide/messages";

export const FaqTabData = [
	{
		key: "Forms",
		title: t.forms(),
	},
	{
		key: "Content",
		title: t.content(),
	},
	{
		key: "Connectivity",
		title: t.connectivity(),
	},
	{
		key: "App",
		title: t.app(),
	},
];

export const FaqQuestionsData = {
	Forms: [
		{
			question: "Ist die Datenerfassung DSGVO-konform?",
			answer:
				"Ja, die Datenerfassung ist DSGVO-konform. Wir bieten Ihnen die Möglichkeit, die Einwilligung zur Datenerfassung in den Formularen zu integrieren. Des Weiteren können Sie die Datenverarbeitung und -speicherung in den Einstellungen anpassen.",
		},
		{
			question: "In welche Sprachen kann ich die Formulare übersetzen?",
			answer:
				"Alle. Sie können die Formulare in jede Sprache übersetzen, die Sie benötigen. Wir unterstützen alle Sprachen und bieten Ihnen die Möglichkeit, die Formulare in mehreren Sprachen zu erstellen.",
		},
	],
	Content: [
		{
			question: "Gibt es eine Begrenzung für die Anzahl der Dateien?",
			answer:
				"Nein, Sie können so viele Dateien hochladen wie Sie möchten. Jedoch gibt es eine Begrenzung für einzelne Dateien und eine Begrenzung für den gesamten Speicherplatz. Stehen Sie vor dem erreichen dieser Grenze kontaktieren wir Sie proaktiv.",
		},
		{
			question: "Sind meine Daten sicher?",
			answer:
				"Die Dateien werden verschlüsselt auf unseren Servern abgelegt und ebenfalss nur durch verschlüsselte Verbindungen übertragen. Wenn Sie weitere oder explizite Fragen zur Sicherheit haben, kontaktieren Sie uns gerne.",
		},
	],
	Connectivity: [
		{
			question: "Welche Systeme können angebunden werden?",
			answer:
				"Generell können alle Systeme angebunden werden, die eine API zur Verfügung stellen. Wir haben bereits Erfahrung mit der Anbindung von CRM-Systemen, ERP-Systemen sowie E-Mail-Marketing-Tools. Kontaktieren Sie uns einfach und wir besprechen gemeinsam Ihre Anforderungen und Wünsche.",
		},
		{
			question:
				"Warum ist für die Anbindung externer Systeme der Enterprise Plan notwenig?",
			answer:
				"Im Enterprise Plan werden dezidierte Server für die Anwendung und Datenbank zur Verfügung gestellt. Dies ermöglicht die individuellen Anpassungen der Software ganz nach Ihren Anforderungen. Des Weiteren erhalten Sie im Enterprise Plan eine individuelle Beratung und Unterstützung bei der Anbindung externer Systeme.",
		},
	],
	App: [
		{
			question: "Benötigt die App eine Internetverbindung?",
			answer:
				"Nein, die App benötigt keine Internetverbindung. Wenn Sie sich angemeldet und die entsprechenden Formulare und Inhalte heruntergeladen haben, können Sie die App auch offline verwenden. Erfasste Daten werden gespeichert und bei der nächsten Verbindung automatisch synchronisiert.",
		},
		{
			question: "Ist es möglich eine individuelle App zu erhalten?",
			answer:
				"Ja, wir bieten auch individuelle App-Entwicklung an. Kontaktieren Sie uns einfach und wir besprechen gemeinsam Ihre Anforderungen und Wünsche. Vorab sei aber gesagt dass die individuelle App-Entwicklung mit höheren Kosten verbunden ist.",
		},
	],
};
