import NavbarDesktop from "./navbarDesktop/navbarDesktop";
import NavbarMobile from "./navbarMobile/navbarMobile";

/**
 * Represents the application's navbar.
 *
 * This component is designed to be used in the application layout. It encapsulates
 * the logic and presentation for the navbar
 *
 * @component
 * @returns {React.ReactElement} The application's navbar.
 */
const Navbar: React.FC = () => {
	return (
		<nav>
			{/* Desktop */}
			<NavbarDesktop />
			{/* Mobile */}
			<NavbarMobile />
		</nav>
	);
};

export default Navbar;
