import {
	faCheckSquare
} from "@fortawesome/pro-duotone-svg-icons";
import * as t from "@/i18n/paraglide/messages";
import type React from "react";
import SectionWrapper from "@/components/ui/layout/sectionWrapper/sectionWrapper";
import StyledImage from "./components/styledImage";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { Icon } from "@/components/ui/icon/icon";
import NewsletterForm from "./components/newsletterForm";

const Newsletter: React.FC = () => {
	return (
		<SectionWrapper title={"Newsletter"}>
			<div className="flex flex-col md:flex-row gap-8 md:gap-16 lg:flex-row max-w-6xl mx-auto">
				<StyledImage src={"/images/newsletter.jpg"} className="h-96" />
				<div className="flex flex-col lg:w-1/2">
					<div className="flex flex-col gap-6">
						<span className="text-lg text-foreground-secondary">
							Melden Sie sich für unseren Newsletter an um regelmäßig
							Neuigkeiten bezüglich neuer Funktionen, Integrationen von neuen
							Partnern oder allgemeinen Informationen direkt in Ihren
							Posteingang zu erhalten.
						</span>
						<div className="flex flex-col gap-2.5">
							<div className="flex gap-2.5 items-center">
								<Icon
									icon={faCheckSquare}
									primaryColor="success"
									secondaryColor="success-3"
									size={IconSize.Large}
								/>
								<span>Informationen über neue Funktionen</span>
							</div>
							<div className="flex gap-2.5 items-center">
								<Icon
									size={IconSize.Large}
									icon={faCheckSquare}
									primaryColor="success"
									secondaryColor="success-3"
								/>
								<span>Benachrichtigungen zu neuen Integrationen</span>
							</div>
							<div className="flex gap-2.5 items-center">
							<Icon
									icon={faCheckSquare}
									primaryColor="success"
									secondaryColor="success-3"
									size={IconSize.Large}
								/>
								<span>Kein Spam - monatlicher Newsletter</span>
							</div>
							<div className="flex gap-2.5 items-center">
							<Icon
									icon={faCheckSquare}
									primaryColor="success"
									secondaryColor="success-3"
									size={IconSize.Large}
								/>
								<span>Abmeldung jederzeit problemlos möglich</span>
							</div>
						</div>
						<NewsletterForm />
					</div>
				</div>
			</div>
		</SectionWrapper>
	);
};

Newsletter.displayName = "Newsletter";
export default Newsletter;
