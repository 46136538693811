/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const registerSuccessSubtitle = () => `Wir haben Ihnen eine Email zugesandt, bitte bestätigen Sie den Link um Ihr Konto zu freizuschalten. Die Email ist für eine Woche gültig.`
