import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { FaqQuestionsData } from "../helpers/faqData";
import type { FaqQuestionProps } from "../helpers/faqTypes";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";
import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";

const FaqQuestions: React.FC<FaqQuestionProps> = ({ selectedTab }) => {
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const toggleQuestion = (index: number) => {
		setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	return (
		<div className="mx-auto mt-6 max-w-3xl">
			<AnimatePresence mode="wait">
				{Object.entries(FaqQuestionsData).map(([tab, questions]) => {
					return selectedTab === tab ? (
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 20 }}
							transition={{
								duration: 0.5,
								ease: "backIn",
							}}
							className="space-y-4"
							key={tab}
						>
							{questions.map((question, index) => {
								const isOpen = openIndex === index;
								return (
									<motion.div
										key={index}
										animate={isOpen ? "open" : "closed"}
										className={`rounded-md border px-4 transition-colors ${
											isOpen
												? "border-background-secondary bg-background-tertiary"
												: "bg-background-secondary"
										}`}
									>
										<button
											type={ButtonType.Button}
											onClick={() => toggleQuestion(index)}
											className="flex w-full items-center justify-between gap-4 py-4"
										>
											<span
												className={`text-left md:text-lg font-medium transition-colors ${
													isOpen ? "" : "text-foreground-secondary"
												}`}
											>
												{question.question}
											</span>
											<motion.span
												variants={{
													open: {
														rotate: "45deg",
													},
													closed: {
														rotate: "0deg",
													},
												}}
											>
												<FontAwesomeIcon
													className={
														"md:text-2xl transition-colors text-foreground"
													}
													size={IconSize.Medium}
													fixedWidth
													icon={faPlus}
												/>
											</motion.span>
										</button>
										<motion.div
											initial={false}
											animate={{
												height: isOpen ? "" : "0px",
												marginBottom: isOpen ? "24px" : "0px",
											}}
											className="overflow-hidden text-foreground-secondary"
										>
											<p>{question.answer}</p>
										</motion.div>
									</motion.div>
								);
							})}
						</motion.div>
					) : undefined;
				})}
			</AnimatePresence>
		</div>
	);
};

FaqQuestions.displayName = "FaqQuestions";
export default FaqQuestions;
