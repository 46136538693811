import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useState } from "react";
import useMeasure from "react-use-measure";
import { usageCardData } from "./helpers/usageData";
import { usageCardSize } from "./helpers/usageTypes";
import UsageCard from "./components/usageCard";
import { FadeInStaggerWrapper, FadeInWrapper } from "@/components/ui/layout/fadeInWrapper/fadeInWrapper";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";

const Usage: React.FC = () => {
	const breakpoints = {
		sm: 640,
		lg: 1024,
	};

	const [ref, { width }] = useMeasure();
	const [offset, setOffset] = useState(0);

	const cardBuffer =
		width > breakpoints.lg ? 3 : width > breakpoints.sm ? 2 : 1;

	const canShiftLeft = offset < 0;

	const canShiftRight =
		Math.abs(offset) < usageCardSize * (usageCardData.length - cardBuffer);

	const shiftLeft = () => {
		if (!canShiftLeft) {
			return;
		}
		setOffset((previousValue) => previousValue + usageCardSize);
	};

	const shiftRight = () => {
		if (!canShiftRight) {
			return;
		}
		setOffset((previousValue) => previousValue - usageCardSize);
	};

	return (
		<section>
			<div className="bg-background-tertiary" ref={ref}>
				<div className="relative overflow-hidden pb-10 pt-6 px-6 mt-16 sm:px-8 md:pt-16 md:pb-16 mb-10 md:mt-32">
					{/* Cards */}
					<FadeInStaggerWrapper className="flex flex-col mx-auto max-w-7xl gap-4">
						<FadeInWrapper>
							<span className="text-4xl md:text-[2.5rem]">Anwendungen</span>
						</FadeInWrapper>
						<FadeInWrapper>
							<motion.div
								animate={{
									x: offset,
								}}
								className="flex"
							>
								{usageCardData.map((card) => {
									return <UsageCard key={card.id} {...card} />;
								})}
							</motion.div>
						</FadeInWrapper>
					</FadeInStaggerWrapper>

					{/* Buttons */}
					<>
						<motion.button
							initial={false}
							animate={{
								x: canShiftLeft ? "0%" : "-100%",
							}}
							aria-label="Gallery Shift Left"
							className="absolute left-0 top-1/2 z-30 rounded-r-xl bg-slate-100/30 p-3 pl-2 text-3xl backdrop-blur-sm transition-[padding] hover:pl-3"
							onClick={shiftLeft}
						>
							<FontAwesomeIcon icon={faChevronLeft} />
						</motion.button>
						<motion.button
							initial={false}
							animate={{
								x: canShiftRight ? "0%" : "100%",
							}}
							aria-label="Gallery Shift Right"
							className="absolute right-0 top-1/2 z-30 rounded-l-xl bg-slate-100/30 p-3 pr-2 text-3xl backdrop-blur-sm transition-[padding] hover:pr-3"
							onClick={shiftRight}
						>
							<FontAwesomeIcon icon={faChevronRight} />
						</motion.button>
					</>
				</div>
			</div>
		</section>
	);
};

Usage.displayName = "Usage";
export default Usage;
