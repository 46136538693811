import type { UsageCardType } from "./usageTypes";

export const usageCardData: UsageCardType[] = [
	{
		id: 1,
		image: "usageCardImage1.jpg",
		futureFeature: false,
		title: "Messe und Ausstellung",
		description: "Erfassung von Kontaktdaten und Feedback.",
	},
	{
		id: 2,
		image: "usageCardImage2.jpg",
		futureFeature: false,
		title: "Produktpräsentationen",
		description: "Präsentation von Broschüren, Preislisten und Videos.",
	},
	{
		id: 3,
		image: "usageCardImage3.jpg",
		futureFeature: false,
		title: "Marktforschung",
		description: "Durchführung von Umfragen und Studien.",
	},
	{
		id: 4,
		image: "usageCardImage4.jpg",
		futureFeature: false,
		title: "Dienstleister",
		description: "Verwaltung von Termin- vereinbarungen und Daten.",
	},
	{
		id: 5,
		image: "usageCardImage5.jpg",
		futureFeature: false,
		title: "Kundendienst",
		description: "Erfassen von Kundenfeedback und Serviceanfragen.",
	},
	{
		id: 6,
		image: "usageCardImage6.jpg",
		futureFeature: true,
		title: "Eventregistrierung",
		description: "Registrierung und Check-in von Teilnehmern.",
	},
];
