import * as t from "@/i18n/paraglide/messages";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { faUserPlus, faArrowRightToBracket } from "@fortawesome/pro-duotone-svg-icons";
import { ButtonType, ButtonIconSide, ButtonVariant } from "@/components/ui/button/helpers/buttonTypes";
import FormItem from "@/components/ui/form/components/formItem/formItem";
import FormLabel from "@/components/ui/form/components/formLabel/formLabel";
import { Form, FormField, FormControl } from "@/components/ui/form/form";
import { Separator } from "@/components/ui/separator/separator";
import { Link } from "@tanstack/react-router";
import Button from "@/components/ui/button/button";
import { Checkbox } from "@/components/ui/checkbox/checkbox";
import FormTextInput from "@/components/ui/form/components/formTextInput/formTextInput";
import { RegisterFormSchema, RegisterFormValues } from "./data/registerFormSchema";
import { useSubmitRegisterForm } from "./hooks/useSubmitRegisterForm";
import { useLanguage } from "@/providers/languageProvider";

interface RegisterFormProps {
	setRegisterCompleted: Dispatch<SetStateAction<boolean>>;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
	setRegisterCompleted,
}) => {
	const form = useForm<z.infer<typeof RegisterFormSchema>>({
		resolver: zodResolver(RegisterFormSchema),
		mode: "onBlur",
	});
	const { locale } = useLanguage(); 
	const { mutate, isPending } = useSubmitRegisterForm();

	const handleSubmit = async (registerFormValues: RegisterFormValues) => {
		const valuesWithLanguage = { ...registerFormValues, language: locale };

		mutate(valuesWithLanguage, {
			onSuccess: () => setRegisterCompleted(true),
		});
	};

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}  className="w-full flex flex-col">
				<div className="mb-8 space-y-4">
					<FormTextInput
							form={form}
							name="organisationName"
							label="Organisation"
							placeholder="Organisation"
							isRequired
							isDisabled={form.formState.isSubmitting}
					/>
					<FormTextInput
							form={form}
							name="email"
							label={t.email()}
							placeholder={t.email()}
							isRequired
							isDisabled={form.formState.isSubmitting}
					/>
					<div className="flex flex-grow gap-3">
						<div className="w-1/2">
						<FormTextInput
								form={form}
								name="firstName"
								label={t.firstName()}
								placeholder={t.firstName()}
								isRequired
								isDisabled={form.formState.isSubmitting}
						/>
						</div>
						<div className="w-1/2">
						<FormTextInput
								form={form}
								name="lastName"
								label={t.lastName()}
								placeholder={t.lastName()}
								isRequired
								isDisabled={form.formState.isSubmitting}
						/>
						</div>
					</div>
					<FormTextInput
							form={form}
							name="password"
							label="Password"
							placeholder="Password"
							isDisabled={form.formState.isSubmitting}
							isRequired
							hasHideOption
					/>
					<div className="pt-2">
					<FormField
						control={form.control}
						name="hasAcceptedTerms"
						render={({ field, fieldState, formState }) => (
							<FormItem className="flex flex-row items-start space-y-0">
								<FormControl>
									<Checkbox
										isSelected={field.value}
										onChange={() => {
											field.onChange(!field.value);
											form.trigger("hasAcceptedTerms");
										}}
									/>
								</FormControl>
								<div className="leading-none">
									<FormLabel className="text-muted-foreground">
										<span>{t.agreeTermsOfServicePartOne()}</span>
										<Link className="link text-foreground font-semibold" to="/terms-of-service">
											{t.termsOfService()}
										</Link>
										<span className={fieldState.error ? "text-error" : "text-warning"}>*</span>
									</FormLabel>
								</div>
							</FormItem>
						)}
					/>
					</div>
				</div>
				<Button
					icon={faUserPlus}
					type={ButtonType.Submit}
					label={t.registrationAction()}
					isLoading={isPending}
					isDisabled={!form.formState.isValid || isPending}
					iconSide={ButtonIconSide.Right}
					className="w-full"
				/>
				<Separator className="w-12 self-center my-5 bg-foreground-muted" />
				<Link to="/login">
					<Button
						variant={ButtonVariant.Outline}
						label={t.loginAction()}
						iconSide={ButtonIconSide.Right}
						icon={faArrowRightToBracket}
						className="w-full"
					/>
					</Link>
			</form>
		</Form>
	);
};
