import { cn } from "@/lib/utils";
import { forwardRef, useState } from "react";
import { Icon } from "../icon/icon";
import { TextInputProps } from "./helpers/textInputTypes";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
	({ className, type, icon, hasHideOption = false, ...props }, ref) => {
		const [isInputVisible, setIsInputVisible] = useState(false);

		if (hasHideOption) {
			type = isInputVisible ? "text" : "password";
		}

		return (
			<div className="w-100 relative flex grow items-center">
				{icon && (
					<div className="absolute left-3 top-1/2 -translate-y-1/2">
						<Icon fixedWidth className="size-4" icon={icon} />
					</div>
				)}
				<input
					type={type}
					value={props.value}
					className={cn(
						"flex h-10 w-full rounded-md border border-input bg-background-tertiary px-3 py-2 text-sm transition duration-300 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground active:border-[#6e7383] hover:border-[#6e7383] focus:border-[#6e7383] focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
						icon ? "pl-10" : "",
						className,
					)}
					ref={ref}
					{...props}
				/>
				{hasHideOption && (
					<div
						className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
						onClick={() => setIsInputVisible(!isInputVisible)}
					>
						{isInputVisible ? (
							<Icon fixedWidth className="size-4" icon={faEyeSlash} />
							) : (
							<Icon fixedWidth className="size-4" icon={faEye} />
						)}
					</div>
				)}
			</div>
		);
	},
);

TextInput.displayName = "TextInput";
export default TextInput;
