import { z } from "zod";
import * as t from "@/i18n/paraglide/messages";

export const RegisterFormSchema = z
	.object({
		organisationName: z.string({ message: t.validationRequired({ fieldName: t.organisation()}) }).min(3, {
			message: t.validationMinLength({ fieldName: t.organisation(), length: 3}),
		}),
		firstName: z.string({ message: t.validationRequired({ fieldName: t.firstName()}) }),
		lastName: z.string({ message: t.validationRequired({ fieldName: t.lastName()}) }),
		email: z.string({ message: t.validationRequired({ fieldName: t.email()}) }).email({
			message: t.validationEmail(),
		}),
		password: z.string({ message: t.validationRequired({ fieldName: t.password()}) }).min(8, {
			message: t.validationMinLength({ fieldName: t.password(), length: 8}),
		}),
		hasAcceptedTerms: z.boolean().refine((val) => val === true, {
			message: t.validationTermsOfService(),
		}),
	})

	export type RegisterFormValues = z.infer<typeof RegisterFormSchema>;