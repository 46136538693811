
import Navbar from '@/components/layout/app/navbar/navbar'
import SidebarDesktop from '@/components/layout/app/sidebar/sidebarDesktop/sidebarDesktop'
import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/_appLayout')({
  component: LandingPageLayout,
})

function LandingPageLayout() {
  return (
<div id="app-root" className="flex flex-row">
			<nav>
				<SidebarDesktop />
			</nav>
			<div className="flex w-full flex-col">
				<Navbar />
				<Outlet />
			</div>
		</div>
  )
}
