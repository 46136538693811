import * as t from "@/i18n/paraglide/messages";
import { Menu, MenuItemType } from "./navbarTypes";
import {
	faAnalytics,
	faBlog,
	faCircleNodes,
	faHeartPulse,
	faImage,
	faListTimeline,
	faRectangleList,
	faShieldCheck,
	faTabletScreen,
	faArrowRight,
	faClipboardListCheck,
	faEnvelopeOpenText,
	faPersonChalkboard,
	faInfo,
	faCalendarUsers,
	faBuildingUser
} from "@fortawesome/pro-duotone-svg-icons";


export const getNavbarData = (): Menu => ({
	items: [
		{
			id: 1,
			title: t.features(),
			type: MenuItemType.Menu,
			menuItems: [
				{
					title: t.forms(),
					Icon: faRectangleList,
					href: "/features",
					hash: "forms",
					description: t.formsDescriptionNavbar(),
					colors: {
						primary: "brescian-blue",
						secondary: "brescian-blue-8",
						tertiary: "brescian-blue-1",
					},
				},
				{
					title: t.content(),
					Icon: faImage,
					href: "/features",
					hash: "content",
					description: t.contentDescriptionShort(),
					colors: {
						primary: "ineffable-green-9",
						secondary: "ineffable-green-8",
						tertiary: "ineffable-green-1",
					},
				},
				{
					title: t.app(),
					Icon: faTabletScreen,
					href: "/features",
					hash: "client",
					description: t.appDescriptionShort(),
					colors: {
						primary: "radiance-blue-9",
						secondary: "radiance-blue-8",
						tertiary: "radiance-blue-1",
					},
				},
				{
					title: t.controlling(),
					Icon: faAnalytics,
					href: "/features",
					hash: "controlling",
					description: t.controllingDescriptionShort(),
					colors: {
						primary: "pumpkin-orange-9",
						secondary: "pumpkin-orange-8",
						tertiary: "pumpkin-orange-1",
					},
				},
				{
					title: t.connectivity(),
					Icon: faCircleNodes,
					href: "/features",
					hash: "connectivity",
					description: t.connectivityDescriptionShort(),
					colors: {
						primary: "queer-purple-9",
						secondary: "queer-purple-8",
						tertiary: "queer-purple-1",
					},
				},
				{
					title: t.allFeatures(),
					Icon: faArrowRight,
					href: "/features",
					description: t.allFeaturesDescription(),
					colors: {
						primary: "blue-rinse-9",
						secondary: "blue-rinse-8",
						tertiary: "blue-rinse-1",
					},
				},
			],
		},
		{
			id: 2,
			title: t.solutions(),
			type: MenuItemType.Menu,
			menuItems: [
				{
					title: t.leadManagment(),
					Icon: faBuildingUser,
					href: "#",
					description: t.leadManagmentDescription(),
					colors: {
						primary: "shamrock",
						secondary: "shamrock-8",
						tertiary: "shamrock-1",
					},
				},
				{
					title: t.salesPresentation(),
					Icon: faPersonChalkboard,
					href: "#",
					description: t.salesPresentationDescription(),
					colors: {
						primary: "pine-glade",
						secondary: "pine-glade-8",
						tertiary: "pine-glade-1",
					},
				},
				{
					title: t.serviceInformation(),
					Icon: faInfo,
					href: "#",
					description: t.serviceInformationDescription(),
					colors: {
						primary: "california",
						secondary: "california-8",
						tertiary: "california-1",
					},
				},
				{
					title: t.eventManagement(),
					Icon: faCalendarUsers,
					href: "#",
					description: t.eventManagementDescription(),
					colors: {
						primary: "blue-chill",
						secondary: "blue-chill-8",
						tertiary: "blue-chill-1",
					},
				},
				{
					title: t.offerCapture(),
					Icon: faEnvelopeOpenText,
					href: "#",
					description: t.offerCaptureDescription(),
					colors: {
						primary: "terracotta",
						secondary: "terracotta-8",
						tertiary: "terracotta-1",
					},
				},
				{
					title: t.researchSurvey(),
					Icon: faClipboardListCheck,
					href: "#",
					description: t.researchSurveyDescription(),
					colors: {
						primary: "cranberry",
						secondary: "cranberry-8",
						tertiary: "cranberry-1",
					},
				},
			],
		},
		{
			id: 3,
			title: t.resources(),
			type: MenuItemType.Menu,
			menuItems: [
				{
					title: t.blog(),
					Icon: faBlog,
					href: "#",
					description: t.blogDescription(),
					colors: {
						primary: "fuchsia-red",
						secondary: "fuchsia-red-8",
						tertiary: "fuchsia-red-1",
					},
				},
				{
					title: "Status",
					Icon: faHeartPulse,
					href: "#",
					description:
						"See the real-time and historical data of our systems perfomance.",
					colors: {
						primary: "flamingo-red",
						secondary: "flamingo-red-8",
						tertiary: "flamingo-red-1",
					},
				},
				{
					title: "Changelog",
					Icon: faListTimeline,
					href: "/changelog",
					description: "Stay up to date with our latest update releases.",
					colors: {
						primary: "khaki",
						secondary: "khaki-8",
						tertiary: "khaki-1",
					},
				},
				{
					title: "Security",
					Icon: faShieldCheck,
					href: "#",
					description: "Learn about our security practices and policies.",
					colors: {
						primary: "flora-green",
						secondary: "flora-green-8",
						tertiary: "flora-green-1",
					},
				},
			],
		},
		{
			id: 4,
			title: t.pricing(),
			type: MenuItemType.Link,
			href: "#pricing",
		},
	],
});
