import { FC, PropsWithChildren } from "react";
import { LanguageProvider } from "./languageProvider";
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";

const Providers: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = new QueryClient();

	return (
    <LanguageProvider>
      <QueryClientProvider client={queryClient}>
        {children}
        {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
        {import.meta.env.DEV && <TanStackRouterDevtools position="bottom-right" />}
      </QueryClientProvider>
    </LanguageProvider>
	);
}

export default Providers;