import { useRef } from "react";
import AppFeature from "./components/appFeature/appFeature";
import ConnectivityFeature from "./components/connectivityFeature/connectivityFeature";
import ContentFeature from "./components/contentFeature/contentFeature";
import ControllingFeature from "./components/controllingFeautre/controllingFeature";
import FormFeature from "./components/formFeature/formFeature";
import FeaturesHeader from "./components/header/featureHeader";


const FeatureScreen: React.FC = () => {
	const formRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const appRef = useRef<HTMLDivElement>(null);
  const controllingRef = useRef<HTMLDivElement>(null);
  const connectivityRef = useRef<HTMLDivElement>(null);

	return (
    <div className="max-w-7xl mx-auto">
			<div className="flex justify-between flex-col gap-12">
				{/* Header */}
				<FeaturesHeader />
				{/* Form */}
				<div id="forms" ref={formRef}>
					<FormFeature />
				</div>
				{/* Content */}
				<div id="content" ref={contentRef}>
					<ContentFeature />
				</div>
				{/* App */}
				<div id="client" ref={appRef}>
			  	<AppFeature />
				</div>
			  {/* Controlling	*/}
				<div id="controlling" ref={controllingRef}>
					<ControllingFeature />
				</div>
				{/* Connectivity */}
				<div id="connectivity" ref={connectivityRef}>
				<ConnectivityFeature />
				</div>
			</div>
		</div>
	);
};

FeatureScreen.displayName = "FeatureScreen";
export default FeatureScreen;