import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export type DeepPick<
	T,
	Path extends string,
> = Path extends `${infer Head}.${infer Tail}`
	? Head extends keyof T
		? DeepPick<T[Head], Tail>
		: never
	: Path extends keyof T
		? T[Path]
		: never;
