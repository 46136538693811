import Container from "@/components/ui/layout/container/container";
import { FadeInStaggerWrapper, FadeInWrapper } from "@/components/ui/layout/fadeInWrapper/fadeInWrapper";
import parse from "html-react-parser";
import HeroBackground from "./components/heroBackground";
import * as t from "@/i18n/paraglide/messages"
import { Link } from "@tanstack/react-router";
import { FC } from "react";
import { useLanguage } from "@/providers/languageProvider";

const Hero: FC = () => {
	const { locale } = useLanguage(); 

	return (
		<div className="flex lg:h-[calc(100vh-5rem)] flex-col lg:justify-center overflow-hidden p-6">
			<Container>
				<div className="flex flex-col lg:flex-row justify-between lg:gap-10">
					<div className="w-full lg:w-1/2 relative mx-auto z-10">
					<FadeInStaggerWrapper>
					<FadeInWrapper>
								<h1 className="text-[1.45rem] md:text-[2.25rem] font-medium leading-relaxed">
									{parse(t.heroTitle())}
								</h1>
								</FadeInWrapper>
								<FadeInWrapper>
								<p className="mt-4 text-[1.125rem] md:text-[1.4rem] text-foreground-secondary leading-relaxed">
									{parse(t.heroSubtitle())}
								</p>
								</FadeInWrapper>
								<FadeInWrapper>
								<div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
									<Link href="/"><img className="w-36 h-12 opacity-80" src={t.appStoreButtonImagePath()}/></Link>
									<Link href="/"><img className="w-40 h-12 opacity-80" src={t.playStoreButtonImagePath()}/></Link>
								</div>
								</FadeInWrapper>
								</FadeInStaggerWrapper>
					</div>
					<div className="relative w-full lg:w-1/2">
						<HeroBackground className="hidden lg:flex absolute left-1/2 size-[800px] -translate-x-1/3 [mask-image:linear-gradient(to_bottom,white_50%,transparent_70%)] sm:-translate-x-1/2" />
						<div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}
Hero.displayName = "Hero";
export default Hero;