import {
	ButtonColor,
	ButtonIconSide,
	ButtonSize,
	ButtonVariant,
} from "./buttonTypes";

export const getButtonStyling = ({
	variant,
	size,
	color,
	hasNotification,
}: {
	variant: ButtonVariant;
	size: ButtonSize;
	color?: ButtonColor;
	hasNotification?: boolean;
}): string => {
	const base =
		"relative group/btn inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none ring-offset-background transition duration-300 disabled:cursor-not-allowed";

	const config = {
		variant: {
			[ButtonVariant.Fill]: `bg-${color ?? "primary"} hover:bg-${color ?? "primary"}-600 text-white disabled:bg-primary-7 disabled:text-white/70 border border-${color ?? "primary"} hover:border-${color ?? "primary"}-600`,
			[ButtonVariant.Outline]: `border hover:bg-background-tertiary ${hasNotification ? "" : "border-input"}`,
			[ButtonVariant.Input]:
				"border border-input bg-background-tertiary hover:border-[#6e7383] focus:border-[#6e7383] focus-visible:outline-none active:border-[#6e7383] text-muted-foreground",
			[ButtonVariant.Ghost]: "",
		},
		size: {
			[ButtonSize.ExtraSmall]: "h-8 px-2 rounded-md text-[13px]",
			[ButtonSize.Small]: "h-9 px-3 rounded-md",
			[ButtonSize.Medium]: "h-10 py-2 px-3",
			[ButtonSize.Large]: "h-11 px-8 rounded-md",
		},
		color: {
			[ButtonColor.Primary]: "",
			[ButtonColor.Error]: "border-error-border hover:bg-error-hover-bg",
			[ButtonColor.Info]: "border-info-border hover:bg-info-hover-bg",
			[ButtonColor.Success]: "border-success-border hover:bg-success-hover-bg",
			[ButtonColor.Warning]: "border-warning-border hover:bg-warning-hover-bg",
		},
	};

	return `${base} ${config.variant[variant]} ${config.size[size]} ${color ? config.color?.[color] : ""}`;
};

export const getButtonIconStyling = ({
	color,
	iconSide,
	isResponsive,
	variant,
}: {
	color?: ButtonColor;
	iconSide?: ButtonIconSide;
	isResponsive?: boolean;
	variant: ButtonVariant;
}): string => {
	const config = {
		variant: {
			[ButtonVariant.Input]: "text-foreground",
			[ButtonVariant.Fill]: "",
			[ButtonVariant.Outline]: "",
			[ButtonVariant.Ghost]: "",
		},
		color: {
			[ButtonColor.Primary]: "text-primary",
			[ButtonColor.Error]: "text-error",
			[ButtonColor.Info]: "text-info",
			[ButtonColor.Success]: "text-success",
			[ButtonColor.Warning]: "text-warning",
		},
		side: {
			[ButtonIconSide.Left]: `${isResponsive ? "sm:mr-1.5" : "mr-1.5"}`,
			[ButtonIconSide.Right]: `${isResponsive ? "sm:ml-1.5" : "ml-1.5"}`,
		},
	};

	return `${iconSide ? config.side[iconSide] : ""} ${color ? config.color?.[color] : ""} ${config.variant[variant]}`;
};
