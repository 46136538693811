import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import FormFeatureTabsHeader from "./components/formFeatureTabsHeader";
import { FormFeatures } from "./helpers/formFeatureTabsData";

const FormFeatureTabs = () => {
	const [selected, setSelected] = useState(0);

	return (
		<section>
			<div className="mx-auto max-w-7xl flex flex-col gap-4">
				<FormFeatureTabsHeader selected={selected} setSelected={setSelected} />
				<div className="bg-background-tertiary border rounded-md">
					<AnimatePresence mode="wait">
						{FormFeatures.map((tab, index) => {
							return selected === index ? (
								<motion.div
									initial={{ opacity: 0, y: 10 }}
									animate={{ opacity: 1, y: 0 }}
									exit={{ opacity: 0, y: 10 }}
									key={index}
									className="flex flex-col gap-6"
								>
									<div className="pl-10 pt-10 flex flex-col gap-2">
										<span className="text-2xl">{tab.title}</span>
										<span>Description</span>
									</div>
									<div className="px-8 pb-8">
										<img
											className="rounded-md"
											src={tab.image}
											alt={tab.title}
										/>
									</div>
								</motion.div>
							) : undefined;
						})}
					</AnimatePresence>
				</div>
			</div>
		</section>
	);
};

FormFeatureTabs.displayName = "FormFeatureTabs";
export default FormFeatureTabs;
