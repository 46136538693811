import { motion, useReducedMotion } from "framer-motion";
import { createContext, useContext } from "react";

const FadeInStaggerContext = createContext(false);

const viewport = { once: true, margin: "0px 0px -200px" };

export function FadeInWrapper(
	props: React.ComponentPropsWithoutRef<typeof motion.div>,
) {
	const shouldReduceMotion = useReducedMotion();
	const isInStaggerGroup = useContext(FadeInStaggerContext);

	return (
		<motion.div
			variants={{
				hidden: { opacity: 0, y: shouldReduceMotion ? 0 : 12 },
				visible: { opacity: 1, y: 0 },
			}}
			transition={{ duration: 0.4 }}
			{...(isInStaggerGroup
				? {}
				: {
						initial: "hidden",
						whileInView: "visible",
						viewport,
					})}
			{...props}
		/>
	);
}

FadeInWrapper.displayName = "FadeInWrapper";

export function FadeInStaggerWrapper({
	faster = false,
	...props
}: React.ComponentPropsWithoutRef<typeof motion.div> & { faster?: boolean }) {
	return (
		<FadeInStaggerContext.Provider value={true}>
			<motion.div
				initial="hidden"
				whileInView="visible"
				viewport={viewport}
				transition={{ staggerChildren: faster ? 0.12 : 0.2 }}
				{...props}
			/>
		</FadeInStaggerContext.Provider>
	);
}

FadeInStaggerWrapper.displayName = "FadeInStaggerWrapper";
