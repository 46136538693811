import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import type { SidebarItemProps } from "./helpers/sidebarItemDesktopTypes";
import { Link, useLocation } from "@tanstack/react-router";
import { IconSize } from "@/components/ui/icon/helpers/iconTypes";

/**
 * Represents a sidebar item in the application.
 *
 * This component displays a linkable item in the sidebar with an associated icon.
 * It takes a `page` from the Page enum which functions as title, a `link` URL, and an `Icon` component as props.
 *
 * @component
 * @param {Page} props.page - The page associated with the sidebar item.
 * @param {string} props.link - The URL to which the sidebar item links.
 * @param {IconProp} props.Icon - The icon displayed within the sidebar item.
 * @returns {React.ReactElement} A sidebar item element.
 */
const SidebarItemDesktop: React.FC<SidebarItemProps> = ({
	page,
	link,
	label,
	Icon,
}) => {
	const location = useLocation();

	return (
		<div>
			<Link to={link} className="w-fit rounded-md">
				<motion.button
					className="group/item relative size-11 rounded-md bg-background-secondary text-md text-foreground duration-300 hover:bg-accent md:size-9 md:w-full md:bg-transparent"
					whileTap={{ scale: 0.98 }}
				>
					<span className="relative z-10 flex cursor-pointer justify-center md:justify-start">
						<span className="hidden items-center gap-3 py-2 pl-3 md:flex md:pl-5">
							{location.pathname.includes(page.toLowerCase()) ? (
								<FontAwesomeIcon
									fixedWidth
									icon={Icon}
									size={IconSize.Medium}
								/>
							) : (
								<FontAwesomeIcon
									fixedWidth
									icon={Icon}
									size={IconSize.Medium}
									className="text-[#8d8f95] duration-300 group-hover/item:text-foreground"
								/>
							)}
							<span>{label}</span>
						</span>
						<span className="md:hidden">
							<FontAwesomeIcon fixedWidth icon={Icon} size={IconSize.Large} />
						</span>
					</span>
					<AnimatePresence>
						{location.pathname.includes(page.toLowerCase()) && (
							<motion.span
								className="absolute inset-0 z-0 rounded-md bg-primary"
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								exit={{ scale: 0 }}
							/>
						)}
					</AnimatePresence>
				</motion.button>
			</Link>
		</div>
	);
};

export default SidebarItemDesktop;
