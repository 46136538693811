import { motion } from "framer-motion";
import * as t from "@/i18n/paraglide/messages";
import {
	BillingPeriod,
	type PricingToggleProps,
} from "../helpers/pricingTypes";
import { ButtonType } from "@/components/ui/button/helpers/buttonTypes";

const PricingToggle: React.FC<PricingToggleProps> = ({
	billingPeriod,
	setBillingPeriod,
}) => {
	return (
		<div className="relative mx-auto flex w-fit items-center rounded-md bg-background-tertiary border-[5px] border-background-tertiary">
			<button
				type={ButtonType.Button}
				className="relative z-10 flex items-center gap-2 py-2.5 px-6 w-32 justify-evenly text-sm font-semibold"
				onClick={() => {
					setBillingPeriod(BillingPeriod.Monthly);
				}}
			>
				<span className="relative z-10">{t.monthly()}</span>
			</button>
			<button
				type={ButtonType.Button}
				className="relative z-10 flex items-center gap-2 py-2.5 px-6 w-32 justify-evenly text-sm font-semibold"
				onClick={() => {
					setBillingPeriod(BillingPeriod.Annually);
				}}
			>
				<span className="relative z-10">{t.annually()}</span>
			</button>
			<div
				className={`absolute inset-0 z-0 flex ${
					billingPeriod === BillingPeriod.Annually
						? "justify-end"
						: "justify-start"
				}`}
			>
				<motion.span
					layout
					transition={{ ease: "easeInOut" }}
					className="h-full w-1/2 rounded-md border bg-primary"
				/>
			</div>
		</div>
	);
};

PricingToggle.displayName = "PricingToggle";
export default PricingToggle;
